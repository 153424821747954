import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import {
  setBetAmount,
  setCoinAmount,
  setCurrency,
  setIsMiniPayTable,
  setIsPopupFreeRoundsOpened,
  setSlotMachineInitialized,
} from '../../gql';
import { formatNumber, normalizeCoins, showCurrency } from '../../utils';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
  miniPayTableNumberStyle,
  miniPayTableTextStyle,
} from '../config';
import { Combos, Icon, IconCombo } from '../d';

class MiniPayTable extends PIXI.Container {
  private id: number;

  private isLast: boolean;

  private currency = '';

  private betAmount: number;

  public iconId: SlotId;

  public combos: Combos;

  public rect: PIXI.Graphics;

  public numbers: PIXI.Text;

  public text: PIXI.Text;

  public sprite: PIXI.Sprite;

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.isLast = id % REELS_AMOUNT === REELS_AMOUNT - 1;
    this.x = this.isLast ? -REEL_WIDTH : 0;
    this.y = 0;
    this.iconId = icon.id;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    this.betAmount = setBetAmount();
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => this.showPayTable(i));
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, () => (this.visible = false));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventManager.addListener(EventTypes.UPDATE_BET, (betAmount: number) => this.handleChangeBetAmount(betAmount));
    // rect
    {
      this.rect = new PIXI.Graphics();
      this.rect.x = this.isLast ? 0 : REEL_WIDTH / 2;
      this.rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
      this.rect.alpha = 0.75;
      this.rect.drawRoundedRect(
        this.isLast ? 0 : 0,
        (SLOT_HEIGHT - PAY_TABLE_HEIGHT) / 2,
        PAY_TABLE_WIDTH,
        PAY_TABLE_HEIGHT,
        15,
      );
      this.rect.zIndex = 2;
      this.addChild(this.rect);
    }

    // sprite
    {
      this.sprite = new PIXI.Sprite();
      const slotPosition = (REEL_WIDTH - SLOT_WIDTH) / 2 + SLOT_WIDTH / 2;
      this.sprite.anchor.set(0.5, 0.5);
      this.sprite.width = SLOT_WIDTH;
      this.sprite.height = SLOT_HEIGHT;
      this.sprite.x = this.isLast ? REEL_WIDTH + slotPosition : slotPosition;
      this.sprite.y = SLOT_HEIGHT / 2;
      this.sprite.zIndex = 3;
      this.addChild(this.sprite);
    }

    // multipliers
    {
      this.numbers = new PIXI.Text('', {
        wordWrapWidth: SLOT_WIDTH,
        ...miniPayTableNumberStyle,
      });
      this.numbers.resolution = 1;
      this.numbers.zIndex = 3;
      this.addChild(this.numbers);
    }

    // multipliersValue
    {
      this.text = new PIXI.Text('', {
        wordWrapWidth: SLOT_WIDTH,
        ...miniPayTableTextStyle,
      });
      this.text.resolution = 1;
      this.text.zIndex = 3;
      this.addChild(this.text);
    }

    this.currency = setCurrency();
    this.setPayTableData(icon.id, combos);
  }

  public setPayTableData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;

    this.sprite.texture = PIXI.Texture.from(MAPPED_SYMBOLS[iconId]);
    this.sprite.width = this.sprite.texture.width;
    this.sprite.height = this.sprite.texture.height;

    this.numbers.text = this.getCombosNumbers();
    this.numbers.x = this.isLast ? 50 : SLOT_WIDTH + 30;
    this.numbers.y = 70;

    this.text.text = this.getCombos();
    this.text.x = this.numbers.x + this.numbers.width + 10;
    this.text.y = 70;
  }

  private calcMultiplier(multiplier: number): number {
    if (this.iconId === SlotId.SC1 || this.iconId === SlotId.SC2) {
      return normalizeCoins(setBetAmount() * multiplier);
    }
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  private getCombosNumbers(): string {
    return this.combos?.reduce((acc: string, curr: IconCombo) => `${acc} ${curr.pattern}: \n`, '') || '';
  }

  private getCombos(): string {
    return (
      this.combos?.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${formatNumber(this.currency, this.calcMultiplier(curr.multiplier), showCurrency(this.currency))}\n`,
        '',
      ) || ''
    );
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!setSlotMachineInitialized()) return;
    if (setIsPopupFreeRoundsOpened()) return;
    if (!setIsMiniPayTable() || !this.combos?.length) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }

  private handleChangeBetAmount(_betAmount: number): void {
    this.betAmount = setBetAmount();
    this.text.text = this.getCombos();
  }
}

export default MiniPayTable;
