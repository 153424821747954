import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useMemo } from 'react';

import { EventTypes } from '../../global.d';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  setIsFreeRoundBonus,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsPopupFreeRoundsOpened,
} from '../../gql';
import { IConfig } from '../../gql/d';
import { eventManager } from '../../slotMachine/config';

const EventListener: React.FC = () => {
  const { data: configData } = useQuery<IConfig>(configGql);
  const { isLeftHandMode, isTurboSpin, isSoundOn, isSpinInProgress, isSlotBusy, isSlotStopped } = configData!;

  const { data: betData } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { betAmount } = betData!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins, autoSpinsLeft } = autoSpins!;

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_AUTO_SPINS, isAutoSpins);
  }, [isAutoSpins]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_AUTO_SPINS_LEFT, autoSpinsLeft);
  }, [autoSpinsLeft]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_LEFT_HAND_MODE, isLeftHandMode);
  }, [isLeftHandMode]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_TURBO_SPIN, isTurboSpin);
  }, [isTurboSpin]);

  useMemo(() => {
    eventManager.emit(EventTypes.TOGGLE_SOUND, isSoundOn);
  }, [isSoundOn]);

  useMemo(() => {
    eventManager.emit(EventTypes.UPDATE_BET, betAmount);
  }, [betAmount]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SPIN_IN_PROGRESS, isSpinInProgress);
  }, [isSpinInProgress]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOT_IS_BUSY, isSlotBusy);
  }, [isSlotBusy]);

  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_SLOTS_STOPPED, isSlotStopped);
  }, [isSlotStopped]);

  const isFreeSpinsWin = useReactiveVar(setIsFreeSpinsWin);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_FREESPINS_WIN, isFreeSpinsWin);
  }, [isFreeSpinsWin]);

  const isInTransition = useReactiveVar(setIsInTransition);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_IN_TRANSITION, isInTransition);
  }, [isInTransition]);

  const isFreeRoundBonus = useReactiveVar(setIsFreeRoundBonus);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_FREE_ROUND_BONUS, isFreeRoundBonus);
  }, [isFreeRoundBonus]);

  const isPopupFreeRoundsOpened = useReactiveVar(setIsPopupFreeRoundsOpened);
  useMemo(() => {
    eventManager.emit(EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED, isPopupFreeRoundsOpened);
  }, [isPopupFreeRoundsOpened]);

  return null;
};

export default EventListener;
