import * as PIXI from 'pixi.js';

import { SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

export const RETRIGGER_MESSAGE_POSITION_X = SLOTS_CONTAINER_WIDTH / 2;
export const RETRIGGER_MESSAGE_POSITION_Y = SLOTS_CONTAINER_HEIGHT / 2;

export const RETRIGGER_MESSAGE_ZOOM_SCALE = 1.2;
export const RETRIGGER_MESSAGE_ZOOM_DURATION = 500;
export const RETRIGGER_MESSAGE_DELAY_DURATION = 3000;
export const RETRIGGER_MESSAGE_FADEOUT_DURATION = 500;

export const retriggerMessageStyle = new PIXI.TextStyle({
  dropShadow: true,
  dropShadowAngle: -5,
  dropShadowAlpha: 0.5,
  dropShadowBlur: 0,
  dropShadowDistance: 8,
  fill: ['#bd0000', '#a92323', '#e34d0d', '#960609', '#ffda81'],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 120,
  stroke: '#ffda81',
  strokeThickness: 5,
  lineJoin: 'round',
  miterLimit: 55,
});
