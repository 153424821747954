export type LotteryPhoenixTable = {
  weight: number;
  isAnimation: boolean;
};

export const lotteryTablePhoenix: LotteryPhoenixTable[] = [
  { weight: 80, isAnimation: false },
  { weight: 20, isAnimation: true },
];

export const lotteryEnabledCheatTablePhoenix: LotteryPhoenixTable[] = [
  { weight: 50, isAnimation: false },
  { weight: 50, isAnimation: true },
];

// PHOENIX
export const PHOENIX_START_MULTIPLIER = 1000;
