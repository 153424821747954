import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setIsPortrait } from '../../gql';
import { isFreeSpinMode } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  FLOWER_ANIMATION_MIX_DURATION,
  FlowerAnimationName,
  changeFlowerAnimationTable,
  initFlowerAnimationTable,
} from './config';

class FreeSpinsBackGroundFlower extends ViewContainer {
  private flowerAnimation: Spine[] = [];

  constructor() {
    super();
    this.initAnimation();

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.manualChangeModeBackground.bind(this));
    eventManager.addListener(EventTypes.SHOW_FREE_SPINS_MULTIPLIER_ANIMATION, this.updateFreeSpinsAmount.bind(this));

    if (setBrokenGame()) {
      this.changeModeBackground({ mode: GameMode.FREE_SPINS }, false);
    }
  }

  private initAnimation(): void {
    this.flowerAnimation = [
      new Spine(PIXI.Loader.shared.resources['freespin_blossom']!.spineData!),
      new Spine(PIXI.Loader.shared.resources['freespin_blossom']!.spineData!),
    ];

    const mixDuration = FLOWER_ANIMATION_MIX_DURATION;
    this.flowerAnimation.forEach((spine) => {
      spine.pivot.x = -10;
      spine.stateData.setMix('fs_ambient', 'fs_ambient2', mixDuration);
      spine.stateData.setMix('fs_ambient2', 'fs_ambient3', mixDuration);
      spine.stateData.setMix('fs_ambient3', 'fs_ambient4', mixDuration);
    });
  }

  private startFlowerAnimation(animationName: FlowerAnimationName) {
    this.flowerAnimation!.forEach((spine) => {
      this.addChild(spine);
      spine.state.setAnimation(0, animationName, true);
    });
  }

  private changeModeBackground(settings: { mode: GameMode }, _isPortrait = setIsPortrait()) {
    if (isFreeSpinMode(settings.mode)) {
      this.visible = true;
      for (let i = 0; i < initFlowerAnimationTable.length; i++) {
        const elem = initFlowerAnimationTable[i]!;
        if (setCurrentBonus().currentRound < elem.stepCount) {
          this.startFlowerAnimation(elem.animationName);
          break;
        }
      }
    } else {
      this.visible = false;
    }
  }
  private manualChangeModeBackground(settings: { mode: GameMode }, _isPortrait = setIsPortrait()) {
    if (isFreeSpinMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  private updateFreeSpinsAmount(current: number) {
    changeFlowerAnimationTable.forEach((multiplier) => {
      if (multiplier.stepCount === current) {
        this.startFlowerAnimation(multiplier.animationName);
      }
    });
  }

  private resize(width: number, height: number) {
    const bgTextureSize = { width: 1980, height: 1080 };
    const bgAspectRatio = bgTextureSize.width / bgTextureSize.height;
    const aspectRatio = width / height;

    let scale = 1;
    if (bgAspectRatio > aspectRatio) {
      scale = width / bgTextureSize.width;
    } else {
      scale = height / bgTextureSize.height;
    }

    if (aspectRatio < 1) {
      scale *= 1.75;
    }

    this.x = width / 2;
    this.y = height / 2;

    this.flowerAnimation.forEach((spine, index) => {
      spine.scale.x = index == 0 ? -scale : scale;
      spine.scale.y = scale;
      spine.position.x = index == 0 ? -width / 2 : width / 2;
      spine.position.y = spine.height / 2;
    });
  }
}

export default FreeSpinsBackGroundFlower;
