import { LinkedListContainer } from '@pixi/particle-emitter';
import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setIsPortrait } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode } from '../../utils';
import Tween from '../animations/tween';
import { eventManager } from '../config';

import {
  ANIMATION_DELAY,
  IMAGE_COUNT_1,
  IMAGE_COUNT_2,
  IMAGE_COUNT_3,
  MAX_IMAGE_COUNT,
  WIND_CHANGE_COUNT,
  moveParameter,
} from './config';
import Flower from './flower';

class FlowerParticle extends LinkedListContainer {
  private flower!: Flower;
  private aryImg: Flower[] = [];

  constructor() {
    super();
    this.visible = false;
    this.setImages();
    this.flow_start();
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.changeMode.bind(this));

    if (setBrokenGame()) {
      this.changeMode({ mode: GameMode.FREE_SPINS }, false);
    }
  }

  private setImages() {
    for (let i = 0; i < MAX_IMAGE_COUNT; i++) {
      this.flower = new Flower(PIXI.Texture.from(ResourceTypes.flowerDenfare));
      this.flower.setImage(moveParameter);
      this.aryImg.push(this.flower);
      this.addChild(this.flower);
    }
  }

  private flow_start() {
    const delay = Tween.createDelayAnimation(ANIMATION_DELAY);
    let windChangeCount = 0;
    delay.addOnStart(() => {
      for (let i = 0; i < MAX_IMAGE_COUNT; i++) {
        this.aryImg[i]!.flow(moveParameter);
        if (setCurrentBonus().currentRound >= 30 && MAX_IMAGE_COUNT > i) {
          this.aryImg[i]!.visible = true;
        } else if (setCurrentBonus().currentRound >= 20 && IMAGE_COUNT_3 > i) {
          this.aryImg[i]!.visible = true;
        } else if (setCurrentBonus().currentRound >= 10 && IMAGE_COUNT_2 > i) {
          this.aryImg[i]!.visible = true;
        } else if (IMAGE_COUNT_1 > i) {
          this.aryImg[i]!.visible = true;
        } else {
          this.aryImg[i]!.visible = false;
        }
        if (windChangeCount > WIND_CHANGE_COUNT) {
          this.windChange();
          windChangeCount = 0;
        }
      }
    });
    delay.addOnComplete(() => {
      delay.start();
      windChangeCount++;
    });
    delay.start();
  }

  private windChange() {
    const wind = Math.random() * (moveParameter.windMax - moveParameter.windMin) + moveParameter.windMin;
    for (let i = 0; i < MAX_IMAGE_COUNT; i++) {
      this.aryImg[i]!.setWind(wind);
    }
  }

  private changeMode(settings: { mode: GameMode }, _isPortrait = setIsPortrait()) {
    if (isFreeSpinMode(settings.mode)) {
      this.visible = true;
    } else {
      this.visible = false;
    }
  }

  private resize(width: number, height: number): void {
    const bgTextureSize = { width: 1980, height: 1080 };
    const bgAspectRatio = bgTextureSize.width / bgTextureSize.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(width / bgTextureSize.width);
    } else {
      this.scale.set(height / bgTextureSize.height);
    }
  }
}
export default FlowerParticle;
