export type FlowerAnimationName = 'fs_ambient' | 'fs_ambient2' | 'fs_ambient3' | 'fs_ambient4';

export const initFlowerAnimationTable: { stepCount: number; animationName: FlowerAnimationName }[] = [
  { stepCount: 10, animationName: 'fs_ambient' },
  { stepCount: 20, animationName: 'fs_ambient2' },
  { stepCount: 30, animationName: 'fs_ambient3' },
  { stepCount: Number.MAX_SAFE_INTEGER, animationName: 'fs_ambient4' },
];

export const changeFlowerAnimationTable: { stepCount: number; animationName: FlowerAnimationName }[] = [
  { stepCount: 10, animationName: 'fs_ambient2' },
  { stepCount: 20, animationName: 'fs_ambient3' },
  { stepCount: 30, animationName: 'fs_ambient4' },
];

export const FLOWER_ANIMATION_MIX_DURATION = 0.4;
