import { IAddOptions, LoaderResource } from 'pixi.js';

import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}
export const MAPPED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.symbolA,
  [SlotId.B]: ResourceTypes.symbolB,
  [SlotId.C]: ResourceTypes.symbolC,
  [SlotId.D]: ResourceTypes.symbolD,
  [SlotId.E]: ResourceTypes.symbolE,
  [SlotId.F]: ResourceTypes.symbolF,
  [SlotId.G]: ResourceTypes.symbolG,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.bonus,
  [SlotId.SC2]: ResourceTypes.bonus,
};

export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: ResourceTypes.symbolABlur,
  [SlotId.B]: ResourceTypes.symbolBBlur,
  [SlotId.C]: ResourceTypes.symbolCBlur,
  [SlotId.D]: ResourceTypes.symbolDBlur,
  [SlotId.E]: ResourceTypes.symbolEBlur,
  [SlotId.F]: ResourceTypes.symbolFBlur,
  [SlotId.G]: ResourceTypes.symbolGBlur,
  [SlotId.WL]: ResourceTypes.wild,
  [SlotId.SC1]: ResourceTypes.bonus,
  [SlotId.SC2]: ResourceTypes.bonus,
};

export const MAPPED_SYMBOLS_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_a',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_b',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_c',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_d',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_e',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_f',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'symbol_g',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'wild',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'bonus_announce',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'bonus_win',
  },
};

export const LOADER_MAPPED_SYMBOLS = [
  {
    name: ResourceTypes.symbolA,
    src: Resources.getSource(ResourceTypes.symbolA),
  },
  {
    name: ResourceTypes.symbolB,
    src: Resources.getSource(ResourceTypes.symbolB),
  },
  {
    name: ResourceTypes.symbolC,
    src: Resources.getSource(ResourceTypes.symbolC),
  },
  {
    name: ResourceTypes.symbolD,
    src: Resources.getSource(ResourceTypes.symbolD),
  },
  {
    name: ResourceTypes.symbolE,
    src: Resources.getSource(ResourceTypes.symbolE),
  },
  {
    name: ResourceTypes.symbolF,
    src: Resources.getSource(ResourceTypes.symbolF),
  },
  {
    name: ResourceTypes.symbolG,
    src: Resources.getSource(ResourceTypes.symbolG),
  },
  { name: ResourceTypes.wild, src: Resources.getSource(ResourceTypes.wild) },
  {
    name: ResourceTypes.bonus,
    src: Resources.getSource(ResourceTypes.bonus),
  },
];

export const LOADER_TEXTURES: IAddOptions[] = [
  ResourceTypes.buyFeatureBtnHover,
  ResourceTypes.buyFeatureBtnNotActive,
  ResourceTypes.buyFeatureBtn,
  ResourceTypes.buyFeatureBtnPressed,
  ResourceTypes.buyFeatureCancelBtnDisable,
  ResourceTypes.buyFeatureCancelBtnHover,
  ResourceTypes.buyFeatureCancelBtn,
  ResourceTypes.buyFeatureCancelBtnPressed,
  ResourceTypes.buyFeatureInput,
  ResourceTypes.buyFeatureMinusBtnDisable,
  ResourceTypes.buyFeatureMinusBtnHover,
  ResourceTypes.buyFeatureMinusBtn,
  ResourceTypes.buyFeatureMinusBtnPressed,
  ResourceTypes.buyFeatureOkBtnDisable,
  ResourceTypes.buyFeatureOkBtnHover,
  ResourceTypes.buyFeatureOkBtn,
  ResourceTypes.buyFeatureOkBtnPressed,
  ResourceTypes.buyFeaturePlusBtnDisable,
  ResourceTypes.buyFeaturePlusBtnHover,
  ResourceTypes.buyFeaturePlusBtn,
  ResourceTypes.buyFeaturePlusBtnPressed,
  ResourceTypes.buyFeaturePopupConfirm,
  ResourceTypes.buyFeaturePopup,
  ResourceTypes.messageBanner,
  ResourceTypes.messageBannerWin,
  ResourceTypes.largeLine,
  ResourceTypes.logo,
  ResourceTypes.mediumLine,
  ResourceTypes.shortLine,
  ResourceTypes.symbolA,
  ResourceTypes.symbolABlur,
  ResourceTypes.symbolB,
  ResourceTypes.symbolBBlur,
  ResourceTypes.symbolC,
  ResourceTypes.symbolCBlur,
  ResourceTypes.symbolD,
  ResourceTypes.symbolDBlur,
  ResourceTypes.symbolE,
  ResourceTypes.symbolEBlur,
  ResourceTypes.symbolF,
  ResourceTypes.symbolFBlur,
  ResourceTypes.symbolG,
  ResourceTypes.symbolGBlur,
  ResourceTypes.wild,
  ResourceTypes.bonus,
  ResourceTypes.backdrop,
  ResourceTypes.buttonCancel,
  ResourceTypes.buttonHistory,
  ResourceTypes.buttonInfo,
  ResourceTypes.buttonLobby,
  ResourceTypes.buttonPlay,
  ResourceTypes.buttonSetting,
  ResourceTypes.infoBtnAutoplay,
  ResourceTypes.infoBtnAutoplayStop,
  ResourceTypes.infoBtnBetSettings,
  ResourceTypes.infoBtnInfo,
  ResourceTypes.infoBtnMenu,
  ResourceTypes.infoBtnSound,
  ResourceTypes.infoBtnSpin,
  ResourceTypes.infoBtnStop,
  ResourceTypes.infoBtnTurbo,
  ResourceTypes.infoPaylines,
  ResourceTypes.introBg,
  ResourceTypes.intro1,
  ResourceTypes.intro2,
  ResourceTypes.intro3,
  ResourceTypes.portraitSlotFreeSpinBg,
  ResourceTypes.slotBg,
  ResourceTypes.slotFreespinBg,
  ResourceTypes.reelframe,
  ResourceTypes.reelback,
  ResourceTypes.titlelogo,
  ResourceTypes.flowerDenfare,
].map((resource) => {
  return { name: resource, url: Resources.getSource(resource) };
});

export const generateTexturePath = (before: string, after: string, _isMobile: boolean): string => {
  return `${before}/${'desktop'}/${after}`;
};

export const SPINE_LOADER_TEXTURES = (isMobile: boolean): IAddOptions[] => {
  return [
    {
      name: 'ambient',
      url: generateTexturePath('/animations', 'ambient/ambient.json', isMobile),
    },
    {
      name: 'symbols',
      url: generateTexturePath('/animations', 'symbol_win/symbols.json', isMobile),
    },
    {
      name: 'multipliers',
      url: generateTexturePath('/animations', 'free_spins/multipliers.json', isMobile),
    },
    {
      name: 'nearmiss',
      url: generateTexturePath('/animations', 'nearmiss/nearmiss.json', isMobile),
    },
    {
      name: 'scatter_long',
      url: generateTexturePath('/animations', 'scatter_long/scatter_long.json', isMobile),
    },
    {
      name: 'freespin_blossom',
      url: generateTexturePath('/animations', 'freespin_blossom/freespin_blossom.json', isMobile),
    },
  ];
};

export const LOADER_SPRITE_TEXTURES: IAddOptions[] = [
  {
    name: 'coinsAnimation',
    url: '/animations/desktop/coins/coins.json',
  },
  {
    name: 'buttonsSprite',
    url: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    url: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    url: '/images/loader/loader.json',
  },
  {
    name: 'phoenixMovie',
    url: '/movie/phoenix.mp4',
    loadType: LoaderResource.LOAD_TYPE.XHR,
  },
];
