import * as PIXI from 'pixi.js';

import { setBrokenGame } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_WIDTH } from '../config';

class GameTitle extends ViewContainer {
  private logo: PIXI.Sprite;

  constructor() {
    super();

    this.logo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.titlelogo));
    this.initGameLogo();
  }

  private initGameLogo(): void {
    this.logo.anchor.set(0.5);
    this.logo.x = GAME_CONTAINER_WIDTH / 2;
    this.logo.y = -90;
    this.logo.scale.set(0.4);
    this.addChild(this.logo);

    this.visible = !setBrokenGame();
  }
}

export default GameTitle;
