import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import Animation from '../animations/animation';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { GAME_CONTAINER_WIDTH, eventManager } from '../config';

import {
  FREE_SPIN_SPIN_ANIMATION_SIZE,
  MULTIPLIER_POSITION_X,
  MULTIPLIER_POSITION_Y,
  MULTIPLIER_TEXT_POSITION_X,
  MULTIPLIER_TEXT_POSITION_Y,
  createBounceAnimation,
  textStyle,
} from './helperConfig';

class FreeSpinsMultipliersAnimationContainer extends ViewContainer {
  private multipliersAnimation: SpineAnimation | null = null;

  private hasStop: boolean;

  private multiplierText = new PIXI.Text('x0', textStyle);

  private multiplierAnimation: Animation | null = null;

  constructor() {
    super();
    this.x = MULTIPLIER_POSITION_X;
    this.y = MULTIPLIER_POSITION_Y;
    this.zIndex = 5;
    this.hasStop = false;
    this.sortableChildren = true;
    this.multiplierText.resolution = 1;
    this.multiplierText.visible = false;
    this.multiplierText.anchor.set(0.5, 0.5);
    this.multiplierText.x = MULTIPLIER_TEXT_POSITION_X;
    this.multiplierText.y = MULTIPLIER_TEXT_POSITION_Y;
    this.multiplierText.zIndex = 2;
    this.addChild(this.multiplierText);
    this.scale.set(GAME_CONTAINER_WIDTH / FREE_SPIN_SPIN_ANIMATION_SIZE);
    this.initAnimation();

    eventManager.addListener(EventTypes.SHOW_FREE_SPINS_MULTIPLIER_ANIMATION, (multiplier: number): void => {
      this.multiplierText.text = `x${multiplier}`;
      this.hasStop = false;
      const delay = Tween.createDelayAnimation(10);
      delay.addOnComplete(() => this.startAnimation('in'));
      delay.start();
    });

    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      this.removeAnimation();
    });
  }

  private initAnimation(): void {
    this.multipliersAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources['multipliers']!.spineData);
    this.multipliersAnimation.addOnStart(() => {
      this.addChild(this.multipliersAnimation!.spine);
    });
  }

  private startAnimation(anim: string): void {
    this.multipliersAnimation!.setAnimation(anim, false);
    if (this.multipliersAnimation?.getSpine()) this.multipliersAnimation.getSpine().visible = true;
    this.multipliersAnimation!.start();
    AudioApi.play({ type: ISongs.SensuOpen, stopPrev: true });

    this.startMultiplierAnimation();
    eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_MULTIPLIER);

    this.multipliersAnimation!.complete = [];
    this.multipliersAnimation!.addOnComplete(() => {
      this.onCompleteAnimation();
    });
  }

  private startMultiplierAnimation(): void {
    this.multiplierAnimation = createBounceAnimation(this.multiplierText);
    this.multiplierAnimation.start();
    this.multiplierAnimation.addOnComplete(() => {
      AudioApi.play({ type: ISongs.SensuClose, stopPrev: true });
    });
  }

  private onCompleteAnimation(): void {
    this.hasStop = true;
  }

  private removeAnimation(): void {
    if (!this.hasStop) {
      eventManager.emit(EventTypes.HANDLE_UPDATE_FREE_SPINS_MULTIPLIER);
      this.multipliersAnimation?.complete;
      this.multipliersAnimation?.skip();
      this.multiplierAnimation?.skip();
      this.multipliersAnimation!.getSpine().visible = false;
    }
  }
}
export default FreeSpinsMultipliersAnimationContainer;
