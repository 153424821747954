import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import { setGameMode } from '../../gql';
import { destroySpine, isFreeSpinMode, isScatter } from '../../utils';
import Animation from '../animations/animation';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ADDITIONAL_SPIN_TIME_PER_REEL,
  ANTICIPATION_DURATION,
  ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
  ANTICIPATION_SLOTS_TINT,
  BASE_REEL_ENDING_DURATION,
  BASE_REEL_ENDING_FORMULA,
  BASE_REEL_ROLLING_DURATION,
  BASE_REEL_STARTING_DURATION,
  BASE_REEL_STARTING_FORMULA,
  FAKE_ROLLING_DURATION,
  FAKE_ROLLING_SLOTS,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  MINIMUM_SPIN_SLOTS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  REEL_STARTING_SLOTS_AMOUNT,
  REEL_WIDTH,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOT_HEIGHT,
  SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  TURBO_ADDITIONAL_SPIN_TIME_PER_REEL,
  TURBO_REEL_ENDING_DURATION,
  TURBO_REEL_ROLLING_DURATION,
  TURBO_REEL_STARTING_DURATION,
  TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL,
  eventManager,
} from '../config';
import Slot from '../slot/slot';
import AnticipationSpinAnimation from '../spin/anticipationSpinAnimation';

import { MAPPED_LONG_SPIN_ANIMATIONS } from './config';
import { IReel } from './d';

class Reel implements IReel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public container: ViewContainer;

  public position = 0;

  public previousPosition = 0;

  public spinAnimation: AnticipationSpinAnimation | null = null;

  public slots: Slot[] = [];

  public animator: () => void = this.reelAnimator.bind(this);

  public isPlaySoundOnStop = false;

  public isTurboSpin = false;

  public size: number;

  public scatterCount = 0;

  public stopScatterPosition = 0;

  private isForceStopped = false;

  public longSpinAnimation: Animation | undefined;

  public nearMissAnimation: Animation | undefined;

  public anticipationCount = 0;

  constructor(id: number, data: SlotId[], startPosition: number) {
    this.id = id;
    this.data = data;
    this.size = data.length;
    this.state = ReelState.IDLE;
    this.container = new ViewContainer();
    this.container.width = REEL_WIDTH;
    this.container.x = id * REEL_WIDTH;
    this.container.y = 0;
    this.createSlots();
    this.position = startPosition === this.size ? 0 : this.size - (startPosition % this.size);
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.clearAnimations();
    });
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, () => {
      if (!this.longSpinAnimation) {
        this.isForceStopped = true;
      } else {
        this.anticipationCount = 0;
      }
    });
  }

  public clean(): void {
    this.container.removeChildren();
    this.slots = [];
  }

  public init(data: SlotId[], position: number): void {
    this.data = data;
    this.size = data.length;
    this.createSlots();
    this.position = position;
  }

  private createNearMissAnimation(): Animation {
    const spineAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources['nearmiss']!.spineData);
    const animation = new Animation({});

    animation.addOnStart(() => {
      spineAnimation.getSpine().x = REEL_WIDTH / 2;
      spineAnimation.getSpine().y = SLOTS_CONTAINER_HEIGHT / 2;
      spineAnimation.getSpine().state.timeScale = 1;
      this.container.addChild(spineAnimation.getSpine());
      spineAnimation.setAnimation('near_miss', true);
    });

    animation.addOnComplete(() => {
      this.container.removeChild(spineAnimation.getSpine());
      destroySpine(spineAnimation);
    });
    animation.addOnSkip(() => {
      this.container.removeChild(spineAnimation.getSpine());
      destroySpine(spineAnimation);
    });

    return animation;
  }

  private createLongSpinAnimation(): Animation {
    const longSpin = new SpineAnimation({}, PIXI.Loader.shared.resources['scatter_long']!.spineData);
    const longSpinAnimation = new Animation({});
    const longSpinAnimationName =
      MAPPED_LONG_SPIN_ANIMATIONS[this.anticipationCount == 1 ? this.stopScatterPosition : 0]!.name;

    longSpinAnimation.addOnStart(() => {
      longSpin.getSpine().x = REEL_WIDTH / 2;
      longSpin.getSpine().y = SLOTS_CONTAINER_HEIGHT / 2;
      this.container.addChild(longSpin.getSpine());
      longSpin.setAnimation(longSpinAnimationName, false);
    });
    longSpinAnimation.addOnComplete(() => {
      this.container.removeChild(longSpin.getSpine());
      destroySpine(longSpin);
    });
    longSpinAnimation.addOnSkip(() => {
      this.container.removeChild(longSpin.getSpine());
      destroySpine(longSpin);
    });

    return longSpinAnimation;
  }

  private onAnticipationStart(index: number): void {
    _.forEach(this.slots, (slot) => {
      if (!isScatter(slot.slotId)) {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      } else {
        slot.zIndex = 3;
      }
    });

    this.clearAnimations();

    if (this.id === index && this.anticipationCount > 0 && !this.isForceStopped) {
      this.nearMissAnimation = this.createNearMissAnimation();
      this.longSpinAnimation = this.createLongSpinAnimation();

      this.nearMissAnimation.start();
      this.longSpinAnimation.start();

      AudioApi.play({ type: ISongs.LongSpin, stopPrev: true });
      this.anticipationCount -= 1;
    }
  }

  private onReelsStopped(): void {
    this.resetSlotsTint();
  }

  private resetSlotsTint(): void {
    _.forEach(this.slots, (slot) => {
      slot.tint = 0xffffff;
    });
    this.isForceStopped = false;
  }

  private createSlots(): void {
    for (let i = 0; i < this.data.length; i++) {
      const slotId = this.data[i % this.data.length]!;
      const slot = new Slot(i, slotId);
      this.slots.push(slot);
      this.container.addChild(slot);
    }
  }

  public getTarget(expected: number): number {
    if (expected - this.position > MINIMUM_SPIN_SLOTS_AMOUNT) {
      return expected;
    }
    let amount = expected - this.position;
    while (amount < MINIMUM_SPIN_SLOTS_AMOUNT) amount += this.data.length;
    return amount + this.position;
  }

  public createSpinAnimation(isTurboSpin: boolean | undefined): AnticipationSpinAnimation {
    this.position %= this.data.length;
    this.isTurboSpin = !!isTurboSpin;
    const rollingTime = isTurboSpin
      ? TURBO_REEL_ROLLING_DURATION + this.id * TURBO_ADDITIONAL_SPIN_TIME_PER_REEL
      : BASE_REEL_ROLLING_DURATION + this.id * ADDITIONAL_SPIN_TIME_PER_REEL;
    const target = this.position + INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP + this.id * 5;

    const starting = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT,
      easing: BASE_REEL_STARTING_FORMULA,
      delay: (isTurboSpin ? TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL : SPIN_REEL_ANIMATION_DELAY_PER_REEL) * this.id,
      duration: isTurboSpin ? TURBO_REEL_STARTING_DURATION : BASE_REEL_STARTING_DURATION,
    });
    starting.addOnStart(() => {
      this.changeState(ReelState.STARTING);
    });

    const fakeRolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: this.position + REEL_STARTING_SLOTS_AMOUNT + FAKE_ROLLING_SLOTS,
      duration: FAKE_ROLLING_DURATION,
    });
    fakeRolling.addOnStart(() => {
      this.changeState(ReelState.ROLLING);
    });

    const rolling = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: this.position + REEL_STARTING_SLOTS_AMOUNT,
      target: target - REEL_ENDING_SLOTS_AMOUNT,
      duration: rollingTime,
    });

    const anticipations = [...Array<Tween>(2)].map((_) => {
      return new Tween({
        object: this,
        property: TweenProperties.POSITION,
        propertyBeginValue: target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
        target,
        easing: BASE_REEL_ENDING_FORMULA,
        duration: ANTICIPATION_DURATION,
      });
    });

    const ending = new Tween({
      object: this,
      property: TweenProperties.POSITION,
      propertyBeginValue: target - REEL_ENDING_SLOTS_AMOUNT,
      target,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isTurboSpin ? TURBO_REEL_ENDING_DURATION : BASE_REEL_ENDING_DURATION,
    });
    ending.addOnStart(() => {
      this.changeState(ReelState.ENDING);
    });
    ending.addOnComplete(() => {
      this.changeState(ReelState.IDLE);
      this.onReelStop();
    });

    this.spinAnimation = new AnticipationSpinAnimation({
      startingAnimation: starting,
      fakeRollingAnimation: fakeRolling,
      rollingAnimation: rolling,
      anticipationAnimations: anticipations,
      endingAnimation: ending,
    });

    return this.spinAnimation;
  }

  private clearAnimations() {
    this.longSpinAnimation?.skip();
    this.longSpinAnimation = undefined;
    this.nearMissAnimation?.skip();
    this.nearMissAnimation = undefined;
  }

  private onReelStop(): void {
    this.nearMissAnimation?.skip();
    this.nearMissAnimation = undefined;
    this.isForceStopped = false;
    this.anticipationCount = 0;
    this.stopScatterPosition = 0;

    const reelStopSoundList = [
      ISongs.SFX_UI_SpinStop,
      ISongs.Scatter_01,
      ISongs.Scatter_02,
      ISongs.Scatter_03,
      ISongs.Scatter_04,
      ISongs.Scatter_05,
    ];

    if (this.isPlaySoundOnStop) {
      const offset = isFreeSpinMode(setGameMode()) ? 3 : 2;
      const idx = this.scatterCount > this.id - offset ? this.scatterCount : 0;
      AudioApi.play({
        type: reelStopSoundList[idx]!,
        stopPrev: true,
      });
      this.isPlaySoundOnStop = false;
      this.scatterCount = 0;
    }
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.ENDING) {
      eventManager.emit(EventTypes.REEL_STOPPED, this.id);

      const reelStopSlots = this.getReelStopSlots(Math.round(this.position));
      _.forEach(reelStopSlots, (slot) => {
        slot.onSlotStopped();
      });
    }
  }

  public stopReel(endingDuration: number): void {
    this.spinAnimation!.getStarting().end();
    this.spinAnimation!.getFakeRolling().end();
    this.spinAnimation!.getRolling().end();
    if (this.spinAnimation!.getAnticipations()) {
      this.spinAnimation!.getAnticipations().forEach((e) => e.end());
    }
    this.spinAnimation!.getEnding().easing = BASE_REEL_ENDING_FORMULA;
    this.spinAnimation!.getEnding().duration = endingDuration;

    this.clearAnimations();
  }

  private getReelStopSlots(position: number): Slot[] {
    const slots: Slot[] = [];
    const top = this.slots.length - ((position % this.slots.length) + 1);
    const middle = position % this.slots.length === 0 ? 0 : this.slots.length - (position % this.slots.length);
    const bottom = (this.slots.length - ((position % this.slots.length) - 1)) % this.slots.length;
    slots.push(this.slots[top]!);
    slots.push(this.slots[middle]!);
    slots.push(this.slots[bottom]!);
    return slots;
  }

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
  }

  public reelAnimator(): void {
    this.previousPosition = this.position;
    // Update symbol positions on reel.
    for (let j = 0; j < this.slots.length; j++) {
      const slot = this.slots[j]!;
      slot.y = ((this.position + j + 2) % this.slots.length) * SLOT_HEIGHT - SLOT_HEIGHT;

      slot.toggleBlur(this.state === ReelState.ROLLING);
    }
  }
}

export default Reel;
