import { SlotId } from '../../config';
import { EventTypes, ReelSet } from '../../global.d';
import { setGameMode, setIsBuyFeaturePurchased } from '../../gql';
import { isFreeSpinMode, lotteryTable } from '../../utils';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_MISS_DURATION,
  ANTICIPATION_REEL_ENDING_FORMULA,
  ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT,
  BASE_SPIN_TIME,
  FORCE_STOP_SPIN_ANIMATION_DURATION,
  FORCE_STOP_SPIN_PER_EACH_DURATION,
  INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP,
  REELS_AMOUNT,
  REEL_ENDING_SLOTS_AMOUNT,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  TURBO_SPIN_TIME,
  eventManager,
} from '../config';

import {
  anticipationEndingDurations,
  lotteryTableBaseGameWithScatter,
  lotteryTableBaseGameWithoutScatter,
  lotteryTableFreeSpinsWithScatter,
  lotteryTableFreeSpinsWithoutScatter,
} from './config';
import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReels(reels, startPosition);

    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, () => {
      this.hideSlots();
    });

    eventManager.addListener(EventTypes.REEL_STOPPED, this.hideSlots.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, () => {
      setIsBuyFeaturePurchased(false);
      this.reels.forEach((reel) => {
        if (reel.state !== ReelState.IDLE) {
          reel.stopReel(0);
        }
      });
    });
    eventManager.addListener(EventTypes.HIDE_STOP_SLOTS_DISPLAY, this.showSlots.bind(this));
    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.setSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.CHANGE_REEL_SET, this.changeReelSet.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));

    this.sortableChildren = true;
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private rollbackReels(positions: number[]): void {
    for (let i = 0; i < positions.length; i++) {
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getStarting());
      eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, this.reels[i]!.spinAnimation?.getFakeRolling());
      this.reels[i]!.position = this.reels[i]!.size - positions[i]!;
      this.reels[i]!.state = ReelState.IDLE;
    }
  }

  private changeReelSet(settings: { reelSet: ReelSet; reelPositions: number[] }): void {
    const reelPositions = settings.reelPositions
      .slice(0, 5)
      .map((position, idx) => (settings.reelSet.layout[idx]!.length - position) % settings.reelSet.layout[idx]!.length);

    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i]!.clean();
      this.reels[i]!.init(settings.reelSet.layout[i]!, reelPositions[i]!);
    }
  }

  private initReels(reels: SlotId[][], startPosition?: number[]): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i]! : 0;
      const reel = new Reel(i, reels[i]!, position);
      this.reels[i] = reel;
      this.addChild(reel.container);

      eventManager.emit(EventTypes.REGISTER_ANIMATOR, reel.animator);
    }
  }

  private forceStopReels(isTurboSpin: boolean): void {
    const stopAllReelsAtSameTime =
      Date.now() - this.reels[0]!.spinAnimation!.startTime < (isTurboSpin ? TURBO_SPIN_TIME : BASE_SPIN_TIME);
    for (let i = 0; i < this.reels.length; i++) {
      if (stopAllReelsAtSameTime && i !== 0) {
        this.reels[i]!.isPlaySoundOnStop = false;
      }
      this.reels[i]!.stopReel(
        stopAllReelsAtSameTime
          ? FORCE_STOP_SPIN_ANIMATION_DURATION
          : FORCE_STOP_SPIN_ANIMATION_DURATION + i * FORCE_STOP_SPIN_PER_EACH_DURATION,
      );
    }
  }

  private prolongTarget = (reel: Reel, minValue: number): number => {
    let res = 0;
    while (res < minValue) res += reel.data.length;
    return res;
  };

  private setAnticipationReelAnimation(
    reel: Reel,
    reelAnticipationCount: number,
    accAnticipationsCounts: number[],
    scatterPositions: number[],
    anticipationReelId: number,
    target: number,
    rolling: Tween,
    anticipations: Tween[],
    ending: Tween,
  ) {
    const reelId = reel.id;
    reel.anticipationCount = reelAnticipationCount;

    let beginValue =
      target -
      INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP -
      ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT -
      reelId * 5 -
      accAnticipationsCounts[reelId - 1]! * (reel.isTurboSpin ? 150 : 55);

    if (beginValue < 0) {
      const prolong = this.prolongTarget(reel, Math.abs(beginValue));
      beginValue += prolong;
      target += prolong;
    }

    // roiling
    rolling.propertyBeginValue = beginValue;
    rolling.target = target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
    rolling.duration += ANTICIPATION_MISS_DURATION * accAnticipationsCounts[reelId - 1]!;

    const totalScatterStopDuration = scatterPositions.reduce((sum, stopPosition, index) => {
      if (index > anticipationReelId && index < reelId) {
        if (stopPosition > 0) {
          sum += ANTICIPATION_MISS_DURATION - anticipationEndingDurations[stopPosition]!;
        }
      }
      return sum;
    }, 0);
    rolling.duration -= totalScatterStopDuration;

    // anticipation
    anticipations.forEach((anticipation, count) => {
      if (reelAnticipationCount - 1 > count) {
        anticipation.propertyBeginValue = target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
        anticipation.target = target;
        anticipation.duration = ANTICIPATION_MISS_DURATION;
        anticipation.easing = (t: number): number => t;
        anticipation.addOnStart(() => {
          reel.changeState(ReelState.ENDING);
          eventManager.emit(EventTypes.ANTICIPATION_STARTS, reelId);
        });
      } else {
        anticipation.duration = 0;
      }
    });

    // ending
    ending.propertyBeginValue = target - ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT;
    ending.target = target;
    ending.duration = anticipationEndingDurations[reel.stopScatterPosition]!;
    ending.easing = ANTICIPATION_REEL_ENDING_FORMULA;
    ending.addOnStart(() => eventManager.emit(EventTypes.ANTICIPATION_STARTS, reelId));
  }

  private lotteryAnticipationCounts(
    anticipationReelId: number,
    scatterCounts: number[],
  ): {
    reelAnticipationCounts: number[];
    accAnticipationsCounts: number[];
  } {
    if (anticipationReelId < REELS_AMOUNT) {
      const arr = new Array<number>(5).fill(0);
      const reelAnticipationCounts = arr.map((_, i) => {
        if (i > anticipationReelId) {
          if (!isFreeSpinMode(setGameMode())) {
            return scatterCounts[i] == 0
              ? lotteryTable(lotteryTableBaseGameWithoutScatter).anticipationCount
              : lotteryTable(lotteryTableBaseGameWithScatter).anticipationCount;
          } else {
            return scatterCounts[i] == 0
              ? lotteryTable(lotteryTableFreeSpinsWithoutScatter).anticipationCount
              : lotteryTable(lotteryTableFreeSpinsWithScatter).anticipationCount;
          }
        }
        return 0;
      });
      const accAnticipationsCounts: number[] = [0];
      reelAnticipationCounts.reduce((acc, v) => {
        accAnticipationsCounts.push(acc + v);
        return acc + v;
      });
      return {
        reelAnticipationCounts,
        accAnticipationsCounts,
      };
    } else {
      return { reelAnticipationCounts: [], accAnticipationsCounts: [] };
    }
  }

  private setupAnimationTarget(
    reelPositions: number[],
    scatterCounts: number[],
    anticipationReelId: number,
    scatterPositions: number[],
  ): void {
    const anticipationCounts = this.lotteryAnticipationCounts(anticipationReelId, scatterCounts);

    for (let reelId = 0; reelId < this.reels.length; reelId++) {
      const reel = this.reels[reelId]!;
      const fakeRolling = reel.spinAnimation!.getFakeRolling();
      const rolling = reel.spinAnimation!.getRolling();
      const anticipations = reel.spinAnimation!.getAnticipations();
      const ending = reel.spinAnimation!.getEnding();
      const target = reel.getTarget(reel.data.length - reelPositions[reelId]!);

      reel.scatterCount = scatterCounts[reelId]!;
      reel.stopScatterPosition = scatterPositions[reelId]!;
      fakeRolling.duration = 0;
      if (reelId <= anticipationReelId) {
        anticipations.forEach((value) => (value.duration = 0));
        rolling.propertyBeginValue =
          target - INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP - REEL_ENDING_SLOTS_AMOUNT - reelId * 5;
        rolling.target = target - REEL_ENDING_SLOTS_AMOUNT;

        ending.propertyBeginValue = target - REEL_ENDING_SLOTS_AMOUNT;
        ending.target = target;
      } else {
        this.setAnticipationReelAnimation(
          reel,
          anticipationCounts?.reelAnticipationCounts[reelId]!,
          anticipationCounts?.accAnticipationsCounts,
          scatterPositions,
          anticipationReelId,
          target,
          rolling,
          anticipations,
          ending,
        );
      }
    }
  }

  private hideSlots(reelId?: number): void {
    const arr = [];
    if (reelId != undefined) {
      for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i * REELS_AMOUNT + reelId);
      }
    } else {
      for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
        arr.push(i);
      }
    }
    this.setSlotsVisibility(arr, false);
  }

  private showSlots(): void {
    const arr = [];
    for (let i = 0; i < REELS_AMOUNT * SLOTS_PER_REEL_AMOUNT; i++) {
      arr.push(i);
    }
    this.setSlotsVisibility(arr, true);
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const position = this.reels[x]!.size - (Math.round(this.reels[x]!.position) % this.reels[x]!.size) + y - 1;
      const normalizedPosition = position === -1 ? this.reels[x]!.size - 1 : position % this.reels[x]!.size;
      const slot = this.reels[x]!.slots[normalizedPosition];

      if (slot) {
        slot.visible = visibility;
      }
    });
  }
}

export default ReelsContainer;
