export enum ResourceTypes {
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  buyFeatureInput = 'buyFeatureInput',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  messageBanner = 'messageBanner',
  messageBannerWin = 'messageBannerWin',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  reelback = 'reelback',
  reelframe = 'reelframe',
  shortLine = 'shortLine',
  bonus = 'bonus',
  symbolA = 'symbolA',
  symbolABlur = 'symbolABlur',
  symbolB = 'symbolB',
  symbolBBlur = 'symbolBBlur',
  symbolC = 'symbolC',
  symbolCBlur = 'symbolCBlur',
  symbolD = 'symbolD',
  symbolDBlur = 'symbolDBlur',
  symbolE = 'symbolE',
  symbolEBlur = 'symbolEBlur',
  symbolF = 'symbolF',
  symbolFBlur = 'symbolFBlur',
  symbolG = 'symbolG',
  symbolGBlur = 'symbolGBlur',
  wild = 'wild',
  titlelogo = 'titlelogo',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonPlay = 'buttonPlay',
  buttonSetting = 'buttonSetting',
  flowerDenfare = 'flowerDenfare',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
  intro1 = 'intro1',
  intro2 = 'intro2',
  intro3 = 'intro3',
  introBg = 'introBg',
  portraitSlotFreeSpinBg = 'portraitSlotFreeSpinBg',
  slotBg = 'slotBg',
  slotFreespinBg = 'slotFreespinBg',
}
