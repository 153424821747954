import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const textStyle: Partial<PIXI.ITextStyle> = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#f6ffd6', '#fff0f0', '#ffbe33', '#793416', '#feb043'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 70,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#000000',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const spinsStyle: Partial<PIXI.ITextStyle> = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#09cec0', '#40e7e5', 'white'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 70,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#000000',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};
