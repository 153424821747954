export interface moveParam {
  aspectMax: number;
  aspectMin: number;
  cvsw: number;
  cvsh: number;
  imgBaseSizeW: number;
  imgBaseSizeH: number;
  speedMax: number;
  speedMin: number;
  angleAdd: number;
  wind: number;
  newWind: number;
  windMax: number;
  windMin: number;
}

export const MAX_IMAGE_COUNT = 200;
export const IMAGE_COUNT_1 = MAX_IMAGE_COUNT / 4;
export const IMAGE_COUNT_2 = MAX_IMAGE_COUNT / 3;
export const IMAGE_COUNT_3 = MAX_IMAGE_COUNT / 2;
export const ANIMATION_DELAY = 10;
export const WIND_CHANGE_COUNT = 6000 / ANIMATION_DELAY;
export const WIND_ACCELERATION_SPEED = 0.1;
const IMAGE_SCALE = 4;
const IMAGE_WEIGHT = IMAGE_SCALE;

export const moveParameter: moveParam = {
  aspectMax: 1.5,
  aspectMin: 0.5,
  cvsw: 2500,
  cvsh: 3000,
  imgBaseSizeW: 15 * IMAGE_SCALE,
  imgBaseSizeH: 15 * IMAGE_SCALE,
  speedMax: 2,
  speedMin: 0.5,
  angleAdd: 1.0,
  wind: 10 * IMAGE_WEIGHT,
  newWind: 15 * IMAGE_WEIGHT,
  windMax: 40 * IMAGE_WEIGHT,
  windMin: 3 * IMAGE_WEIGHT,
};
