export const gameRules = [
  { key: 'infoGameRules1_1' },
  { key: 'infoGameRules1_2' },
  { key: 'infoGameRules1_3' },
  { key: 'infoGameRules1_4' },
  { key: 'infoGameRules1_5' },
  { key: 'infoGameRules1_6' },
  { key: 'infoGameRules1_7' },
  { key: 'infoGameRules1_8' },
  { key: 'infoGameRules1_9' },
  { key: 'infoGameRules1_10' },
];

export const infoFeatureFreeSpinDescs = [
  'infoFeatureFreeSpinDesc_1',
  'infoFeatureFreeSpinDesc_2',
  'infoFeatureFreeSpinDesc_3',
  'infoFeatureFreeSpinDesc_4',
  'infoFeatureFreeSpinDesc_5',
  'infoFeatureFreeSpinDesc_6',
  'infoFeatureFreeSpinDesc_7',
  'infoFeatureFreeSpinDesc_8',
  'infoFeatureFreeSpinDesc_9',
  'infoFeatureFreeSpinDesc_10',
];

export const infoBuyFeatureDesc = [
  'infoBuyFeatureDesc_1',
  'infoBuyFeatureDesc_2',
  'infoBuyFeatureDesc_3',
  'infoBuyFeatureDesc_4',
];
