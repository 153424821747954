import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const titleStyle = {
  fontSize: 60,
  fontFamily: font,
  whiteSpace: 'normal',
  fontStyle: 'italic',
  leading: '',
  align: 'center',
  fill: ['#ffffff'],
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 20,
};

export const subtitleStyle = {
  fontSize: 100,
  fontFamily: font,
  whiteSpace: 'normal',
  fontStyle: 'italic',
  leading: '',
  align: 'center',
  fill: ['#fafa00', '#fafa00', '#fa9614', '#fafa00'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  stroke: '#c84614',
  strokeThickness: 9,
  dropShadow: true,
  dropShadowAngle: -5,
  dropShadowBlur: 0,
  dropShadowDistance: 8,
  dropShadowColor: 0x000000,
  padding: 50,
  lineJoin: 'round',
};
export const additionalStyle = {
  fontSize: 40,
  fontFamily: font,
  whiteSpace: 'normal',
  leading: '',
  align: 'center',
  fill: ['#ff8800', '#ff0000', '#880000'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  lineJoin: 'round',
};

export const winTitleStyle = {
  fontSize: 100,
  fontFamily: font,
  whiteSpace: 'normal',
  fontStyle: 'italic',
  leading: '',
  align: 'center',
  fill: ['#ffffff'],
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  fontWeight: 'bolder',
  lineJoin: 'round',
  padding: 20,
};

export const winSubtitleStyle = {
  fontSize: 140,
  fontFamily: font,
  whiteSpace: 'normal',
  fontStyle: 'italic',
  leading: '',
  align: 'center',
  fill: ['#fafa00', '#fafa00', '#fa9614', '#fafa00'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  stroke: '#c84614',
  strokeThickness: 9,
  dropShadow: true,
  dropShadowAngle: -5,
  dropShadowBlur: 0,
  dropShadowDistance: 8,
  dropShadowColor: 0x000000,
  padding: 50,
  fontWeight: 'bolder',
  lineJoin: 'round',
};

export const btnStyle: Partial<PIXI.ITextStyle> = {
  fontSize: 80,
  fontFamily: font,
  whiteSpace: 'normal',
  fontStyle: 'italic',
  // leading: '',
  align: 'center',
  fill: ['#ffffff'],
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 2,
  lineJoin: 'round',
  padding: 20,
};
