import { IAnimation } from './d';

class Animation implements IAnimation {
  public delay: number;

  public change: (() => void)[];

  public complete: (() => void)[];

  public skips: (() => void)[];

  public starts: (() => void)[];

  public easing: (n: number) => number;

  public duration: number;

  public startTime: number;

  public isLoop: boolean;

  public ended = false;

  public update: ((newValue: number) => void) | null;

  constructor(options: IAnimation) {
    this.delay = options.delay ?? 0;
    this.change = options.change ?? [];
    this.complete = options.complete ?? [];
    this.skips = options.skips ?? [];
    this.starts = options.starts ?? [];
    this.easing = options.easing ?? ((n) => n);
    this.duration = options.duration ?? 0;
    this.startTime = options.startTime ?? -1;
    this.isLoop = options.isLoop ?? false;
    this.update = options.update ?? null;
  }

  public end(): void {}

  public start(): void {
    this.onStart();
    this.startTime = Date.now() + this.delay;
  }

  public onStart(): void {
    this.starts.forEach((callback) => callback());
  }

  public onComplete(): void {
    if (!this.isLoop) this.ended = true;
    this.complete.forEach((callback) => callback());
    if (this.isLoop) this.start();
  }

  public onChange(): void {
    this.change.forEach((callback) => callback());
  }

  public onSkip(_isLoop?: boolean): void {
    this.ended = true;
    this.skips.forEach((callback) => callback());
  }

  public addOnChange(callback: () => void): void {
    this.change.push(callback);
  }

  public addOnComplete(callback: () => void): void {
    this.complete.push(callback);
  }

  public removeOnComplete(callback: () => void): void {
    const index = this.complete.indexOf(callback);
    if (index !== -1) {
      this.complete.splice(index, 1);
    }
  }

  public addOnSkip(callback: () => void): void {
    this.skips.push(callback);
  }

  public addOnStart(callback: () => void): void {
    this.starts.push(callback);
  }

  public skip(_isLoop?: boolean): void {
    this.isLoop = false;
    this.duration = 0;
    this.onSkip();
  }
}

export default Animation;
