import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, bonusesId } from '../../global.d';
import { setIsBuyFeaturePopupOpened, setIsBuyFeaturePurchased } from '../../gql';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import { eventManager } from '../config';

import {
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE2_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y,
  FEATURE_POPUP_POSITION_X,
  FEATURE_POPUP_POSITION_Y,
  FEATURE_POPUP_SCALE,
} from './config';
import {
  betValueStyleConfirm,
  buyFeatureConfirmStyle,
  buyFeatureConfirmStyle2,
  totalCostTextStyle,
} from './textStyles';

class BuyFeaturePopupConfirm extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private coinAmount: number;

  private titleText: PIXI.Text;

  private titleText2: PIXI.Text;

  private totalCostText: PIXI.Text;

  private totalCostValue: PIXI.Text;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  constructor() {
    super();
    this.visible = false;
    this.coinAmount = 1;
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.titleText2 = this.initTitle2();
    if (this.titleText.scale.x > this.titleText2.scale.x) {
      this.titleText.scale.set(this.titleText2.scale.x);
    } else {
      this.titleText2.scale.set(this.titleText.scale.x);
    }
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.interactive = true;
    this.init();

    this.scale.set(FEATURE_POPUP_SCALE);
    this.position.set(FEATURE_POPUP_POSITION_X, FEATURE_POPUP_POSITION_Y);

    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    });
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, (totalCost: string, coinAmount: number) => {
      this.handleOpen(totalCost, coinAmount);
    });

    eventManager.on(EventTypes.FORCE_CLOSE_BUY_FEATURE, () => {
      setIsBuyFeaturePopupOpened(false);
      this.handleClose();
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP, false);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.titleText2);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.anchor.set(0.5, 0.5);
    return popupBg;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyFeatureConfirmTitle'), buyFeatureConfirmStyle);
    title.resolution = 1;
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, this.popupBg.width - 200, isMobile ? 450 : 300);
    return title;
  }

  private initTitle2(): PIXI.Text {
    const title = new PIXI.Text(i18n.t<string>('buyFeatureConfirmTitle2'), buyFeatureConfirmStyle2);
    title.resolution = 1;
    title.y = FEATURE_POPUP_CONFIRM_TITLE2_POSITION_Y;
    title.anchor.set(0.5, 0.5);
    updateTextScale(title, this.popupBg.width - 200, isMobile ? 450 : 300);
    return title;
  }

  private initTotalCostText(): PIXI.Text {
    const totalCostText = new PIXI.Text(i18n.t<string>('buyFeatureTotalCost'), totalCostTextStyle);
    totalCostText.resolution = 1;
    totalCostText.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y;
    totalCostText.anchor.set(0.5, 0);

    return totalCostText;
  }

  private initTotalCostValue(): PIXI.Text {
    const totalCostValue = new PIXI.Text('0', betValueStyleConfirm);
    totalCostValue.resolution = 1;
    totalCostValue.y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y;
    totalCostValue.anchor.set(0.5, 0);

    return totalCostValue;
  }

  private initCancelBtn(): PIXI.Sprite {
    const cancelBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn));
    cancelBtn.y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X;
    cancelBtn.anchor.set(0.5);
    cancelBtn.interactive = true;
    cancelBtn.buttonMode = true;
    cancelBtn.on('click', () => {
      this.handleClose();
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
    });
    cancelBtn.on('touchstart', () => {
      this.handleClose();
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
    });

    cancelBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover);
    });
    cancelBtn.addListener('mouseout', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });
    cancelBtn.addListener('mousedown', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed);
    });
    cancelBtn.addListener('mouseup', () => {
      this.cancelBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn);
    });

    return cancelBtn;
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn));
    okBtn.y = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X;
    okBtn.anchor.set(0.5);
    okBtn.interactive = true;
    okBtn.buttonMode = true;
    okBtn.on('click', () => this.handleClickOk());
    okBtn.on('touchstart', () => this.handleClickOk());
    okBtn.addListener('mouseover', () => {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover);
    });
    okBtn.addListener('mouseout', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    });
    okBtn.addListener('mousedown', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed);
    });
    okBtn.addListener('mouseup', () => {
      this.okBtn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn);
    });

    return okBtn;
  }

  private handleClickOk(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    AudioApi.play({ type: ISongs.SFX_BuyFeature });
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, bonusesId[GameMode.BUY_FEATURE]);
    setIsBuyFeaturePurchased(true);
  }

  public handleOpen(totalCost: string, coinAmount: number): void {
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public handleClose(): void {
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP);
    this.visible = false;
  }
}

export default BuyFeaturePopupConfirm;
