import {
  BetBonusReward,
  BetReward,
  BonusStatus,
  EventTypes,
  GameMode,
  ISettledBet,
  UserBonus,
  bonusesId,
} from '../../global.d';
import { client } from '../../gql';
import {
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setFreeRoundBonus,
  setGameMode,
  setSlotConfig,
} from '../../gql/cache';
import { getUserBonuses } from '../../gql/query';
import { eventManager } from '../config';

export const isGetFreeSpinBonus = (nextResult: ISettledBet) => {
  const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

  return !!nextResult?.rewards?.filter(isBonusReward).find((reward) => {
    return reward?.userBonus?.bonusId === bonusesId[GameMode.FREE_SPINS];
  })?.userBonus;
};

export const getFreeRoundBonus = (nextResult: ISettledBet) => {
  return nextResult.bet.data.bonuses.find((e) => e.isFreeBet);
};

export const getUserActiveBonuses = async () => {
  return client.query<{ userBonuses: UserBonus[] }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });
};

export const updateCoinValueFromFreeRoundBonus = () => {
  setCoinValue(setFreeRoundBonus().coinValue);
  setCoinAmount(setFreeRoundBonus().coinAmount);
  setBetAmount(setFreeRoundBonus().coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const isStartFreeSpinRound = () => {
  return setGameMode() === GameMode.FREE_SPINS && setCurrentBonus().currentRound === 0;
};
