import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import SlotMachine from '..';
import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ReelState, eventManager } from '../config';

class Phoenix extends PIXI.Container {
  private videoResource: PIXI.VideoResource;

  private video: PIXI.Sprite;

  private isPlay: boolean;

  constructor() {
    super();
    this.visible = false;
    this.isPlay = false;

    const base = new PIXI.Sprite(PIXI.Texture.WHITE);
    base.anchor.set(0.5);
    base.tint = 0;
    base.width = 4096;
    base.height = 4096;

    this.videoResource = new PIXI.VideoResource(PIXI.Loader.shared.resources['phoenixMovie']!.url, {
      autoPlay: false,
    });
    const baseTex = new PIXI.BaseTexture(this.videoResource);
    const tex = new PIXI.Texture(baseTex);
    this.video = new PIXI.Sprite(tex);
    this.video.anchor.set(0.5);
    this.video.scale.set(1.5);
    this.addChild(base, this.video);
    this.zIndex = 1000;

    this.initMovieListener();
    eventManager.addListener(EventTypes.PHOENIX_START, this.phoenixMovieStart.bind(this));

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private initMovieListener(): void {
    this.videoResource.source.addEventListener('ended', () => {
      this.visible = false;
    });
    this.videoResource.source.addEventListener('play', () => {
      if (!this.isPlay) {
        return;
      }
      this.isPlay = false;
      this.visible = true;
      this.alpha = 0;
      const chain = new AnimationChain();
      chain.appendAnimation(
        new Tween({
          object: this,
          propertyBeginValue: 0,
          target: 1,
          property: TweenProperties.ALPHA,
          duration: 200,
        }),
      );
      chain.appendAnimation(Tween.createDelayAnimation(this.videoResource.source.duration * 1000 - 600));
      chain.appendAnimation(
        new Tween({
          object: this,
          propertyBeginValue: 1,
          target: 0,
          property: TweenProperties.ALPHA,
          duration: 600,
        }),
      );
      chain.start();
    });
    this.videoResource.source.addEventListener('seeked', () => {
      if (this.isPlay) {
        this.videoResource.source.play();
      }
    });
  }

  private phoenixMovieStart(): void {
    AudioApi.play({ type: ISongs.PHOENIX_VO });
    const movieDelay = Tween.createDelayAnimation(750);
    movieDelay.addOnComplete(() => {
      if (SlotMachine.getInstance().reelsContainer.reels[1]!.state === ReelState.IDLE) {
        return;
      }
      this.videoResource.source.pause();
      if (this.videoResource.source.fastSeek) {
        this.videoResource.source.fastSeek(0);
      } else {
        this.videoResource.source.currentTime = 0;
      }
      this.isPlay = true;
    });
    movieDelay.start();
  }

  private resize(width: number, height: number): void {
    this.video.position.set(width / 2, height / 2);

    const tex = this.video.texture;
    const bgAspectRatio = tex.width / tex.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.video.scale.set(height / 960);
    } else {
      this.video.scale.set(width / 960);
    }
  }
}
export default Phoenix;
