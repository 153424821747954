import { EventTypes, GameMode, reelSets } from '../global.d';
import { setIsAutoSpins, setIsBuyFeaturePurchased, setPrevReelSetId } from '../gql/cache';
import { eventManager } from '../slotMachine/config';

export function fallBackReelPosition() {
  if (setIsBuyFeaturePurchased()) {
    eventManager.emit(EventTypes.FORCE_CLOSE_BUY_FEATURE);
  } else {
    setPrevReelSetId() === '' && setPrevReelSetId(reelSets[GameMode.REGULAR]);

    eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
    if (setIsAutoSpins()) setIsAutoSpins(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
  }
}
