import * as PIXI from 'pixi.js';

import { WIND_ACCELERATION_SPEED, moveParam } from './config';

class Flower extends PIXI.Sprite {
  private posX = 0;
  private posY = 0;
  private widthSize = 0;
  private heightSize = 0;
  private myAngle = 0;

  private gravity = 0;
  private wind = 0;
  private newWind = 0;

  private sin = 0;

  constructor(texture: PIXI.Texture) {
    super(texture);
  }

  public setImage(moveparam: moveParam) {
    let aspect = 0;
    aspect = Math.random() * (moveparam.aspectMax - moveparam.aspectMin) + moveparam.aspectMin;

    this.posX = Math.random() * moveparam.cvsw;
    this.posY = Math.random() * moveparam.cvsh;
    this.widthSize = moveparam.imgBaseSizeW * aspect;
    this.heightSize = moveparam.imgBaseSizeH * aspect;
    this.gravity = Math.random() * (moveparam.speedMax - moveparam.speedMin) + moveparam.speedMin;
    this.myAngle = Math.random() * 360;
    this.wind = moveparam.wind;
    this.newWind = moveparam.newWind;

    this.width = this.widthSize;
    this.height = this.heightSize;
    this.anchor.set(Math.random() * 0.5);

    this.updateImages();
  }

  public flow(moveparam: moveParam) {
    if (this.newWind != this.wind) {
      this.newWind > this.wind ? (this.wind += WIND_ACCELERATION_SPEED) : (this.wind -= WIND_ACCELERATION_SPEED);
    }
    this.posX += this.wind / this.widthSize;
    this.posY += this.gravity;
    this.myAngle += moveparam.angleAdd;
    this.sin = Math.sin(this.myAngle * PIXI.DEG_TO_RAD);
    if (this.posY >= moveparam.cvsh) {
      this.posY = -this.heightSize;
    }
    if (this.posX >= moveparam.cvsw) {
      this.posX = -this.widthSize;
    }
    this.updateImages();
  }

  public setWind(newWind: number) {
    this.newWind = newWind;
  }

  private updateImages() {
    this.position.set(this.posX, this.posY);
    this.skew.set(this.sin, this.sin);
    this.angle = this.myAngle;
  }
}
export default Flower;
