import {
  ANTICIPATION_LONG_SPIN_PER1_DURATION,
  ANTICIPATION_LONG_SPIN_PER2_DURATION,
  ANTICIPATION_LONG_SPIN_PER3_DURATION,
  ANTICIPATION_MISS_DURATION,
  ANTICIPATION_STOP_PER1_DURATION,
  ANTICIPATION_STOP_PER2_DURATION,
  ANTICIPATION_STOP_PER3_DURATION,
} from '../config';

import { LotteryAnticipationCountTable } from './d';

export const lotteryTableBaseGameWithoutScatter: LotteryAnticipationCountTable[] = [
  { weight: 80, anticipationCount: 1 },
  { weight: 20, anticipationCount: 2 },
  { weight: 0, anticipationCount: 3 },
];

export const lotteryTableBaseGameWithScatter: LotteryAnticipationCountTable[] = [
  { weight: 30, anticipationCount: 1 },
  { weight: 60, anticipationCount: 2 },
  { weight: 10, anticipationCount: 3 },
];

export const lotteryTableFreeSpinsWithoutScatter: LotteryAnticipationCountTable[] = [
  { weight: 90, anticipationCount: 1 },
  { weight: 10, anticipationCount: 2 },
  { weight: 0, anticipationCount: 3 },
];

export const lotteryTableFreeSpinsWithScatter: LotteryAnticipationCountTable[] = [
  { weight: 40, anticipationCount: 1 },
  { weight: 60, anticipationCount: 2 },
  { weight: 0, anticipationCount: 3 },
];

export const MAPPED_LONG_SPIN_ANIMATIONS: { name: string; duration: number }[] = [
  { name: 'through', duration: ANTICIPATION_MISS_DURATION },
  { name: 'stoppage_1', duration: ANTICIPATION_LONG_SPIN_PER1_DURATION },
  { name: 'stoppage_2', duration: ANTICIPATION_LONG_SPIN_PER2_DURATION },
  { name: 'stoppage_3', duration: ANTICIPATION_LONG_SPIN_PER3_DURATION },
];

export const anticipationEndingDurations = [
  ANTICIPATION_MISS_DURATION,
  ANTICIPATION_STOP_PER1_DURATION,
  ANTICIPATION_STOP_PER2_DURATION,
  ANTICIPATION_STOP_PER3_DURATION,
];
