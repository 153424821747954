import _ from 'lodash';

import { EventTypes } from '../../global.d';
import AnimationChain from '../animations/animationChain';
import { AnticipationSpinAnimationTypes, IAnticipationSpinAnimation } from '../animations/d';
import Tween from '../animations/tween';
import { eventManager } from '../config';

class AnticipationSpinAnimation extends AnimationChain {
  private anticipationAnimationCount = 0;

  constructor(options: IAnticipationSpinAnimation) {
    super(options);
    this.anticipationAnimationCount = 0;
    this.appendAnimation(options.startingAnimation);
    this.appendAnimation(options.fakeRollingAnimation);
    this.appendAnimation(options.rollingAnimation);

    if (options.anticipationAnimations) {
      options.anticipationAnimations.forEach((animation) => {
        this.appendAnimation(animation);
        this.anticipationAnimationCount += 1;
      });
    }

    this.appendAnimation(options.endingAnimation);
    eventManager.addListener(EventTypes.BREAK_SPIN_ANIMATION, this.breakSpinAnimation.bind(this));
  }

  public getStarting(): Tween {
    return this.animations[AnticipationSpinAnimationTypes.START] as Tween;
  }

  public getFakeRolling(): Tween {
    return this.animations[AnticipationSpinAnimationTypes.FAKE_ROLLING] as Tween;
  }

  public getRolling(): Tween {
    return this.animations[AnticipationSpinAnimationTypes.ROLLING] as Tween;
  }

  public getAnticipations(): Tween[] {
    return this.animations.slice(
      AnticipationSpinAnimationTypes.ENDING,
      AnticipationSpinAnimationTypes.ENDING + this.anticipationAnimationCount,
    ) as Tween[];
  }

  public getEnding(): Tween {
    return this.animations[AnticipationSpinAnimationTypes.ENDING + this.anticipationAnimationCount] as Tween;
  }

  private breakSpinAnimation(): void {
    _.forEach(this.animations, (animation) => eventManager.emit(EventTypes.REMOVE_TWEEN_ANIMATION, animation));
  }
}

export default AnticipationSpinAnimation;
