import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH } from '../config';

export const BANNER_POSITION_X = -5;
export const BANNER_POSITION_Y = 60;
export const BANNER_WIDTH = GAME_CONTAINER_WIDTH + 60;
export const BANNER_HEIGHT = GAME_CONTAINER_HEIGHT + 60;

// export const BANNER_BACKGROUND_COLOR = 0xffffff;

export const titlePosition = -225;
export const subtitlePosition = -25;
export const additionalPosition = 270;
export const buttonPosition = 200;

export const winTitlePosition = -150;
export const winSubtitlePosition = 60;
