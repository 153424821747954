import { EventTypes, ISettledBet } from '../../global.d';
import { isScatter } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_SLOTS_TINT,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { IWinLine, Icon } from '../d';

import { SpineAnimateSlot } from './spineAnimateSlot';

class SlotsAnimationContainer extends ViewContainer {
  private slotSymbols: SpineAnimateSlot[] = [];

  public animation?: AnimationChain | undefined;

  private isSpinResult = false;

  constructor() {
    super();
    this.sortableChildren = true;

    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, this.onStartSpin.bind(this));
    eventManager.on(EventTypes.SETUP_BET_RESULT, this.onSetupBetResult.bind(this));
    eventManager.addListener(EventTypes.ANTICIPATION_STARTS, this.onAnticipationStart.bind(this));
    eventManager.addListener(EventTypes.REEL_STOPPED, this.onReelStopped.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.resetSlotsTint.bind(this));
    eventManager.addListener(EventTypes.SKIP_WIN_SLOTS_ANIMATION, this.skipWinSlotsAnimation.bind(this));
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.onStartWinAnimation.bind(this));
    eventManager.addListener(EventTypes.SHOW_STOP_SLOTS_DISPLAY, (spinResult) => {
      this.animation?.skip();
      this.animation = undefined;
      this.initSymbols(spinResult);
      this.slotSymbols.forEach((symbol) => (symbol.visible = true));
    });
    eventManager.addListener(EventTypes.START_RETRIGGER_FEATURE, this.onStartRetrigger.bind(this));
  }

  private clearSymbols(): void {
    this.slotSymbols.forEach((symbol) => {
      symbol.skip();
    });
    this.removeChild(...this.slotSymbols);
    this.slotSymbols = [];
  }

  private initSymbols(spinResult: Icon[]): void {
    this.clearSymbols();

    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      for (let j = 0; j < REELS_AMOUNT; j++) {
        const symbol = new SpineAnimateSlot(spinResult[i * REELS_AMOUNT + j]!.id, j * SLOTS_PER_REEL_AMOUNT + i);
        symbol.x = REEL_WIDTH * j + REEL_WIDTH / 2;
        symbol.y = SLOT_HEIGHT * i + SLOT_HEIGHT / 2;
        this.addChild(symbol);
        this.slotSymbols.push(symbol);
        symbol.visible = false;
      }
    }
  }

  private onStartSpin() {
    this.slotSymbols.forEach((symbol) => {
      this.removeChild(symbol);
    });
    this.isSpinResult = false;
  }

  private onAnticipationStart(): void {
    this.slotSymbols.forEach((slot) => {
      if (isScatter(slot.slotId)) {
        slot.zIndex = 3;
      } else {
        slot.tint = ANTICIPATION_SLOTS_TINT;
      }
    });
  }

  private onSetupBetResult(nextResult: ISettledBet): void {
    if (this.isSpinResult) return;

    this.initSymbols(nextResult.bet.result.spinResult);
    this.isSpinResult = true;
  }

  private onReelStopped(reelId: number): void {
    for (let i = 0; i < SLOTS_PER_REEL_AMOUNT; i++) {
      const symbol = this.slotSymbols[i * REELS_AMOUNT + reelId]!;
      symbol.visible = true;
      symbol.startStopAnimation();
    }
  }

  private resetSlotsTint(): void {
    this.slotSymbols.forEach((slot) => {
      slot.tint = 0xffffff;
    });
  }

  private onStartWinAnimation(nextResult: ISettledBet, _isTurboSpin: boolean) {
    this.showWin(nextResult);
  }

  private onStartRetrigger(nextResult: ISettledBet) {
    if (nextResult.bet.result.winCoinAmount > 0) return;
    this.showWin(nextResult);
  }

  private skipWinSlotsAnimation() {
    this.animation?.skip();
    this.animation = undefined;
    this.slotSymbols.forEach((symbol) => symbol.skip());
  }

  private showWin(nextResult: ISettledBet) {
    console.clear;
    const { paylines } = nextResult;
    this.animation = new AnimationChain();
    this.animation.addOnSkip(() => {
      eventManager.emit(EventTypes.SHOW_TINT, false);
      eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
    });

    const set = new Set<number>();
    paylines.forEach((payLine) => {
      payLine.winPositions.forEach((position) => {
        set.add(position);
      });
    });

    {
      const allSlotsHighlight = this.highlightSlots(Array.from(set));
      allSlotsHighlight.addOnStart(() => {
        if (this.animation) {
          eventManager.emit(EventTypes.SHOW_WIN_LINES, paylines);
        }
      });
      allSlotsHighlight.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, paylines);
        eventManager.emit(EventTypes.SHOW_TINT, false);
      });

      this.animation.appendAnimation(allSlotsHighlight);
    }

    {
      const eachSlotsHighlight = this.createHighlightChainAnimation(paylines, true);
      this.animation.appendAnimation(eachSlotsHighlight);
    }
    this.animation?.start();
  }

  private highlightSlots(slotPositions: number[]): Animation {
    const animationGroup = new AnimationGroup({});
    slotPositions.forEach((position) => {
      animationGroup.addAnimation(this.slotSymbols[position]!.getWinAnimation());
    });
    return animationGroup;
  }

  private createHighlightChainAnimation(paylines: IWinLine[], isLoop: boolean): Animation {
    const animationChain = new AnimationChain({ isLoop });
    paylines.forEach((payline) => {
      const chain = this.highlightSlots(payline.winPositions);
      chain.addOnStart(() => {
        if (this.animation) {
          eventManager.emit(EventTypes.SHOW_WIN_LINES, [payline]);
        }
      });

      chain.addOnComplete(() => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [payline]);
      });

      animationChain.appendAnimation(chain);
    });
    return animationChain;
  }
}

export default SlotsAnimationContainer;
