import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  Scatter_01 = '004_06_Scatter_01',
  Scatter_02 = '004_07_Scatter_02',
  Scatter_03 = '004_08_Scatter_03',
  Scatter_04 = '004_09_Scatter_04',
  Scatter_05 = '004_10_Scatter_05',
  FeatureTrigger = '004_12_FeatureTrigger',
  FeatureReTrigger = '004_13_FeatureReTrigger',
  PHOENIX_VO = '010_38_Yocho_Phoenix',
  BaseGameBGM_Base = '017_01_BaseGameBGM_Base',
  BaseGameBGM_Melo = '017_02_BaseGameBGM_Melo',
  FreeSpinBGM_Loop = '017_03_FreeSpinBGM',
  BigWin_Loop = '017_04_BigWin_Loop',
  BigWin_End = '017_05_BigWin_END',
  LongSpin = '017_11_LongSpin',
  SensuOpen = '017_15_SENSU_OPEN',
  SensuClose = '017_16_SENSU_CLOSE',
  TotalWinBanner = '017_17_TotalWinBanner',
  SFX_BuyFeature = 'SFX_BuyFeature',
  Win_End = 'SFX_SM_CountUp_End',
  Win_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_BIG = 'SFX_Win_Big',
  SFX_WIN_EPIC = 'SFX_Win_Epic',
  SFX_WIN_GREAT = 'SFX_Win_Great',
  SFX_WIN_MEGA = 'SFX_Win_Mega',
}

export const audioSprite: AudioSprite = {
  [ISongs.Scatter_01]: [0, 175.71428571428572],
  [ISongs.Scatter_02]: [2000, 162.40362811791397],
  [ISongs.Scatter_03]: [4000, 502.5623582766441],
  [ISongs.Scatter_04]: [6000, 519.3877551020405],
  [ISongs.Scatter_05]: [8000, 519.9999999999995],
  [ISongs.FeatureTrigger]: [10000, 879.8412698412701],
  [ISongs.FeatureReTrigger]: [12000, 433.3333333333336],
  [ISongs.PHOENIX_VO]: [14000, 4033.333333333331],
  [ISongs.BaseGameBGM_Base]: [20000, 70243.92290249433, true],
  [ISongs.BaseGameBGM_Melo]: [92000, 70243.92290249432, true],
  [ISongs.FreeSpinBGM_Loop]: [164000, 60000, true],
  [ISongs.BigWin_Loop]: [225000, 60000, true],
  [ISongs.BigWin_End]: [286000, 4774.263038548725],
  [ISongs.LongSpin]: [292000, 2367.755102040803],
  [ISongs.SensuOpen]: [296000, 600.5215419501155],
  [ISongs.SensuClose]: [298000, 599.13832199544],
  [ISongs.TotalWinBanner]: [300000, 4411.111111111097],
  [ISongs.SFX_BuyFeature]: [306000, 666.6666666666856],
  [ISongs.Win_End]: [308000, 933.4693877551103],
  [ISongs.Win_Loop]: [310000, 399.7505668934309, true],
  [ISongs.SFX_UI_AutoSpin]: [312000, 320.47619047619946],
  [ISongs.SFX_UI_BetChange]: [314000, 32.08616780045759],
  [ISongs.SFX_UI_Close]: [316000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [318000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [320000, 151.76870748297233],
  [ISongs.SFX_UI_MaxBet]: [322000, 122.92517006801518],
  [ISongs.SFX_UI_MenuOpen]: [324000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [326000, 249.59183673468033],
  [ISongs.SFX_UI_SpinStop]: [328000, 151.02040816327644],
  [ISongs.SFX_WIN_BIG]: [330000, 1622.3356009070358],
  [ISongs.SFX_WIN_EPIC]: [333000, 1862.2675736961583],
  [ISongs.SFX_WIN_GREAT]: [336000, 2118.390022675726],
  [ISongs.SFX_WIN_MEGA]: [340000, 1696.1678004535088],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.Scatter_01]: 0.3,
  [ISongs.Scatter_02]: 0.3,
  [ISongs.Scatter_03]: 0.3,
  [ISongs.Scatter_04]: 0.3,
  [ISongs.Scatter_05]: 0.3,
  [ISongs.FeatureTrigger]: 0.3,
  [ISongs.FeatureReTrigger]: 0.3,
  [ISongs.PHOENIX_VO]: 0.3,
  [ISongs.BaseGameBGM_Base]: 0.3,
  [ISongs.BaseGameBGM_Melo]: 0.2,
  [ISongs.FreeSpinBGM_Loop]: 0.2,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.LongSpin]: 0.3,
  [ISongs.SensuOpen]: 0.5,
  [ISongs.SensuClose]: 0.7,
  [ISongs.TotalWinBanner]: 0.5,
  [ISongs.SFX_BuyFeature]: 0.3,
  [ISongs.Win_End]: 0.7,
  [ISongs.Win_Loop]: 0.5,
  [ISongs.SFX_UI_AutoSpin]: 0.3,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
