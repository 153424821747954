import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { Play } from '@phoenix7dev/audio-api/dist/d';

import { ISongs, SlotId } from '../config';
import { EventTypes, GameMode } from '../global.d';
import { setBrokenGame, setGameMode, setIsSuspended } from '../gql';
import SpineAnimation from '../slotMachine/animations/spine';
import { eventManager } from '../slotMachine/config';
import { Icon } from '../slotMachine/d';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key!]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_SPINS;
};
export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return mode === GameMode.BUY_FEATURE;
};
export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR;
};
export const nextTick = (callback: () => void): void => {
  window.setTimeout(callback, 0);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * (bet.lines || 25)) / 100;
};

const createFasBetArrMin = (amount: number, defaultValue: number) => {
  const arr = new Array(amount).fill(defaultValue);
  return arr.map((item, index) => item * (index + 1));
};

const createFastBetArrMax = (minBet: number, maxBet: number) => {
  const arr = [0, 10, 18, 27, 38, 50, 67, 78, 90, 100];
  const cef = maxBet / minBet / 100;
  return arr.map((item) => {
    const i = Math.round(cef * item);
    return minBet * i || minBet;
  });
};

// const createFastBetArrMax = (minBet: number, maxBet: number) => {
//   const cef = maxBet / minBet / 10;
//   const arr = new Array(10).fill(minBet);
//   return arr.map((item, index) => {
//     const v = index ? index + 1 : 1;
//     const i = Math.floor(cef * v);
//     return item * i;
//   });
// };

export const createFastBet = (minBet = 25, maxBet?: number): number[] => {
  if (!maxBet) {
    return [25, 50, 75, 100];
  }

  if (!(maxBet % minBet)) {
    const amount = maxBet / minBet;
    if (amount <= 10) {
      return createFasBetArrMin(amount, minBet);
    }
    return createFastBetArrMax(minBet, maxBet);
  }

  return [];
};

export const getIconById = (icons: Icon[], id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  throw error;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  sessionStorage.setItem('positions', btoa(positions));
};

export const destroySpine = (spine: SpineAnimation): void => {
  nextTick(() => {
    if (spine.getSpine() && spine.getSpine().skeleton) {
      spine.getSpine().destroy();
    }
  });
};

export const calcPercentage = (initialValue: number, percent: number): number => {
  return (initialValue / 100) * percent;
};

export const isScatter = (slotId: SlotId): boolean => {
  return slotId === SlotId.SC1 || slotId === SlotId.SC2;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isBuyFeaturePopupOpened,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isBuyFeaturePopupOpened: boolean;
}): boolean => {
  if (gameMode === GameMode.REGULAR && isFreeSpinsWin) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isBuyFeatureMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (options: Partial<DropShadowFilterOptions>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return new DropShadowFilter(options) as PIXI.Filter;
};

export const getPlayListOnBroken = (isDuringBigWinLoop: boolean): Play[] => {
  if (isDuringBigWinLoop) {
    return [{ type: ISongs.FreeSpinBGM_Loop, volume: 0 }, { type: ISongs.BigWin_Loop }];
  }
  return [{ type: ISongs.FreeSpinBGM_Loop }];
};

export const restoreGameModeBgm = () => {
  if (AudioApi.isRestricted) {
    return;
  }

  AudioApi.stop({ type: ISongs.BaseGameBGM_Base });

  if (isFreeSpinMode(setGameMode())) {
    AudioApi.play({ type: ISongs.FreeSpinBGM_Loop });
  } else {
    AudioApi.play({ type: ISongs.BaseGameBGM_Base });
  }
};

export const handleChangeRestriction = (): void => {
  setIsSuspended(false);
  AudioApi.unSuspend();
  if (setBrokenGame()) {
    AudioApi.changeRestriction(false, []);
    eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
  } else {
    AudioApi.changeRestriction(false, [
      { type: ISongs.BaseGameBGM_Base },
      { type: ISongs.BaseGameBGM_Melo, volume: 0 },
    ]);
  }
};

export const urlSearchParams = new URLSearchParams(window.location.search);

export const isDemo = urlSearchParams.has('isDemo');
