// eslint-disable-next-line
import { freemem } from 'os';
import * as PIXI from 'pixi.js';

import { EventTypes, FreeSpinsTitleProps, GameMode, MessageBannerProps } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setIsTimeoutErrorMessage, setReplayBet } from '../../gql';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isFreeSpinMode, isRegularMode, updateCoinValueAfterBonuses } from '../../utils';
import ViewContainer from '../components/container';
import {
  LEFT_LINES_NUMBERS_CONTAINER_POSITIONS,
  LINES_NUMBER_CONTAINER_HEIGHT,
  LINES_NUMBER_CONTAINER_WIDTH,
  REEL_BACK_DEFAULT_ALPHA,
  REEL_BACK_FREE_SPIN_ALPHA,
  RIGHT_LINES_NUMBERS_CONTAINER,
  SHOW_LINES_NUMBER_CONTAINER,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import { IGameContainer } from '../d';
import FreeSpinsMultipliersAnimationContainer from '../freeSpins/freeSpinsMultipliersAnimationContainer';
import FreeSpinsTitle from '../freeSpins/freeSpinsTitle';
import MessageBanner from '../messageBanner/messageBanner';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

window.PIXI = PIXI;

class GameView extends ViewContainer {
  public linesContainer: PIXI.Container;

  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public tintContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: PIXI.Container;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public freeSpinsMultipliersAnimationContainer: FreeSpinsMultipliersAnimationContainer | null = null;

  public reelFrame: PIXI.Sprite;

  public reelBack: PIXI.Sprite;

  public freeSpinsTitle: FreeSpinsTitle | null = null;

  public maskArea: PIXI.Graphics;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.sortableChildren = true;
    this.maskArea = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.slotsContainer.scale.set(1, 1);
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    if (SHOW_LINES_NUMBER_CONTAINER) {
      this.leftLinesContainer = new PIXI.Container();
      this.leftLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.leftLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.rightLinesContainer = new PIXI.Container();
      this.rightLinesContainer.width = LINES_NUMBER_CONTAINER_WIDTH;
      this.rightLinesContainer.height = LINES_NUMBER_CONTAINER_HEIGHT;
      this.initLinesNumberContainers();
    }
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.linesContainer = props.linesContainer;
    this.tintContainer = props.tintContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.linesContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.reelBack = this.initReelsBack();
    this.reelFrame = this.initReelsFrame();
    this.addChild(this.reelBack);
    this.addChild(this.slotsContainer);
    this.addChild(this.reelFrame);
    this.addChild(this.gameTitle);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameReplay);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);

    if (setBrokenGame()) {
      this.reelBack.alpha = REEL_BACK_FREE_SPIN_ALPHA;
      this.freeSpinsTitle = new FreeSpinsTitle({
        text: i18n.t<string>('freeSpinsTitle'),
        spins: `${setCurrentBonus().rounds}`,
        currentSpin: `${setCurrentBonus().currentRound}`,
      });
      this.addChild(this.freeSpinsTitle);
      this.freeSpinsMultipliersAnimationContainer = new FreeSpinsMultipliersAnimationContainer();
      this.addChild(this.freeSpinsMultipliersAnimationContainer);
    }
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpins.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpins.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createFreeSpinsMessage.bind(this));
  }

  private createFreeSpinsMessage(props: MessageBannerProps): void {
    if (props.title?.key === 'freeSpinsMessageBannerWinText') {
      if (setIsTimeoutErrorMessage() && !setReplayBet()) return;
    }

    this.addChild(new MessageBanner(props));
  }

  private removeFreeSpins(): void {
    if (this.freeSpinsTitle) {
      this.removeChild(this.freeSpinsTitle);
      this.freeSpinsTitle = null;
    }

    if (this.freeSpinsMultipliersAnimationContainer) {
      this.removeChild(this.freeSpinsMultipliersAnimationContainer);
      this.freeSpinsMultipliersAnimationContainer = null;
    }
  }

  private createFreeSpins(props: FreeSpinsTitleProps): void {
    if (!this.freeSpinsTitle && !this.freeSpinsMultipliersAnimationContainer) {
      this.freeSpinsTitle = new FreeSpinsTitle(props);
      this.freeSpinsMultipliersAnimationContainer = new FreeSpinsMultipliersAnimationContainer();
      this.addChild(this.freeSpinsMultipliersAnimationContainer);
      this.addChild(this.freeSpinsTitle);
    }
  }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isFreeSpinMode(settings.mode)) {
      this.gameTitle.visible = false;
      this.reelBack.alpha = REEL_BACK_FREE_SPIN_ALPHA;
    } else {
      this.gameTitle.visible = true;
      this.reelBack.alpha = REEL_BACK_DEFAULT_ALPHA;
    }
    if (isRegularMode(settings.mode)) {
      updateCoinValueAfterBonuses();
    }
  }

  public initLinesNumberContainers(): void {
    LEFT_LINES_NUMBERS_CONTAINER_POSITIONS.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.leftLinesContainer!.addChild(sprite);
    });
    this.leftLinesContainer!.x = 0;
    this.addChild(this.leftLinesContainer!);

    RIGHT_LINES_NUMBERS_CONTAINER.forEach((line) => {
      const sprite = new PIXI.Sprite(PIXI.Texture.EMPTY);
      sprite.interactive = true;
      sprite.x = line.x;
      sprite.y = line.y;
      sprite.on('mouseover', () => {
        eventManager.emit(EventTypes.SHOW_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.on('mouseout', () => {
        eventManager.emit(EventTypes.HIDE_WIN_LINES, [{ lineId: line.id }]);
      });
      sprite.addChild(new PIXI.Text(`${line.id + 1}`));
      this.rightLinesContainer!.addChild(sprite);
    });
    this.rightLinesContainer!.x = SLOTS_CONTAINER_WIDTH + LINES_NUMBER_CONTAINER_WIDTH;
    this.addChild(this.rightLinesContainer!);
  }

  private initReelsFrame() {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelframe));
    frame.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    frame.anchor.set(0.5, 0.5);
    return frame;
  }

  private initReelsBack() {
    const back = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelback));
    back.position.set(SLOTS_CONTAINER_WIDTH / 2, SLOTS_CONTAINER_HEIGHT / 2);
    back.anchor.set(0.5, 0.5);
    return back;
  }

  private resize(
    _width: number,
    _height: number,
    _x: number,
    _y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.scale.set(scale);
    this.pivot.set(pivotX, pivotY);
  }
}

export default GameView;
