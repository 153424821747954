import i18n from 'i18next';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import {
  setIsShowSoundToast,
  setIsSoundLoading,
  setIsSoundOn,
  setProgress,
  setSkipIntroScreen,
  setSoundValue,
} from '../../gql';
import { ResourceTypes } from '../../resources.d';
import Switch from '../../slotMachine/button/switch';
import { APPLICATION_TRANSPARENT, eventManager } from '../../slotMachine/config';
import { dropShadowFilter, handleChangeRestriction } from '../../utils';

import Carousel from './Carousel/index';

const GAP_Y = 15;
const GAP_X = 15;

class IntroScreen {
  private readonly application: PIXI.Application;

  private container: PIXI.Container;

  private controlsContainer: PIXI.Container;

  private static introScreen: IntroScreen;

  public static initIntroScreen = (): void => {
    IntroScreen.introScreen = new IntroScreen();
  };

  public static getInstance = (): IntroScreen => IntroScreen.introScreen;

  private background = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.introBg));

  private backgroundContainer = new PIXI.Container();

  private playBtn: PIXI.Sprite;

  private soundSwitch: Switch;

  private skipIntroSwitch: Switch;

  private carousel: Carousel;

  private bindedResize = this.resize.bind(this);

  private constructor() {
    this.application = new PIXI.Application({
      resolution: (window.devicePixelRatio || 1) * 1.2,
      autoDensity: true,
      transparent: APPLICATION_TRANSPARENT,
      width: window.innerWidth,
      height: window.innerHeight,
    });
    this.background.anchor.set(0.5);
    this.backgroundContainer.addChild(this.background);
    this.application.stage.addChild(this.backgroundContainer);
    this.container = new PIXI.Container();
    this.container.sortableChildren = true;
    this.controlsContainer = new PIXI.Container();
    this.carousel = new Carousel(400, introContents);
    this.carousel.zIndex = 2;
    this.container.addChild(this.carousel);
    this.playBtn = this.initPlayBtn();
    this.controlsContainer.addChild(this.playBtn);
    this.soundSwitch = this.initSoundSwitchBtn();
    this.controlsContainer.addChild(this.soundSwitch);
    this.skipIntroSwitch = this.initSkipIntroSwitchBtn();
    this.controlsContainer.addChild(this.skipIntroSwitch);
    this.container.addChild(this.controlsContainer);
    this.application.stage.addChild(this.container);
    eventManager.addListener(EventTypes.RESIZE, this.bindedResize);
    eventManager.on(EventTypes.SOUND_INITIALIZED, () => {
      AudioApi.setSoundState(setIsSoundOn());
    });
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private initPlayBtn = (): PIXI.Sprite => {
    const texture = PIXI.Texture.from(ResourceTypes.buttonPlay);
    const btn = new PIXI.Sprite(texture);
    btn.anchor.set(0, 0);
    btn.width = 200;
    btn.height = 50;
    btn.y = 0;
    btn.x = 0;
    btn.buttonMode = true;
    btn.interactive = true;

    const dropShadow = dropShadowFilter({
      color: 0x000000,
      resolution: 4 * PIXI.settings.FILTER_RESOLUTION,
      alpha: 0.4,
    });
    const clickCallback = () => {
      AudioApi.restrictionChangedOnIntroScreen = true;
      if (!AudioApi.isInitialized) {
        eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
      }
      AudioApi.changeRestriction(
        false,
        [],
        () => setIsSoundLoading(true),
        () => {
          setIsShowSoundToast(false);
          handleChangeRestriction();
        },
      );

      setProgress({ ...setProgress(), wasLoaded: true });

      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    btn.on('mousedown', () => {
      btn.filters = [dropShadow];
      btn.height = 49;
    });
    btn.on('mouseout', () => {
      btn.filters = [];
      btn.height = 50;
    });
    btn.on('touchstart', () => {
      btn.filters = [dropShadow];
      btn.height = 49;
    });
    btn.on('touchendoutside', () => {
      btn.filters = [];
      btn.height = 50;
    });
    btn.once('click', clickCallback);
    btn.once('touchend', clickCallback);

    return btn;
  };

  private initSoundSwitchBtn = (): Switch => {
    const btn = new Switch({
      label: i18n.t<string>('sound'),
      textMaxWidth: 500,
      textMobileMaxWidth: 120,
      textMaxHeight: 90,
      textMobileMaxHeight: 50,
    });
    btn.y = this.playBtn.height + GAP_Y;
    btn.setActive(setIsSoundOn());
    btn.interactive = true;
    btn.on('pointerdown', () => {
      const newState = !setIsSoundOn();
      setIsSoundOn(newState);
      AudioApi.setSoundState(newState);
      setSoundValue(newState ? 1 : 0);
    });
    return btn;
  };

  private initSkipIntroSwitchBtn = (): Switch => {
    const btn = new Switch({
      label: i18n.t<string>('show'),
      textMaxWidth: 500,
      textMobileMaxWidth: 120,
      textMaxHeight: 90,
      textMobileMaxHeight: 50,
    });
    btn.y = this.playBtn.height + GAP_Y;
    btn.x = this.soundSwitch.width + GAP_X;
    btn.interactive = true;
    btn.on('pointerdown', () => {
      setSkipIntroScreen(!setSkipIntroScreen());
    });

    return btn;
  };

  private setBgSize = (width: number, height: number): void => {
    this.backgroundContainer.x = width / 2;
    this.backgroundContainer.y = height / 2;

    const bgAspectRatio = this.background.width / this.background.height;
    const aspectRatio = width / height;
    if (bgAspectRatio > aspectRatio) {
      this.backgroundContainer.scale.set(height / this.background.height);
    } else {
      this.backgroundContainer.scale.set(width / this.background.width);
    }
  };

  private setControlsPositions = (width: number, _height: number): void => {
    this.playBtn.x = (this.controlsContainer.width - this.playBtn.width) / 2;
    this.controlsContainer.y = this.carousel.height + GAP_Y;
    this.controlsContainer.x = width / 2 - this.controlsContainer.width / 2;
  };

  private resize(width: number, height: number): void {
    this.setBgSize(width, height);
    this.carousel.setSize(width, height, this.controlsContainer.height + GAP_Y * 5);

    this.setControlsPositions(width, height);
    this.container.y = height / 2 - this.container.height / 2;
    this.application.renderer.resize(width, height);
  }

  private destroy(): void {
    this.application.destroy(true, {
      children: true,
      texture: true,
      baseTexture: true,
    });
    eventManager.removeListener(EventTypes.RESIZE, this.bindedResize);
  }
}

export default IntroScreen;
