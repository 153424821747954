import * as PIXI from 'pixi.js';

import { EventTypes } from '../../global.d';
import ViewContainer from '../components/container';
import {
  TINT_ALPHA,
  TINT_BACKGROUND_COLOR,
  TINT_CONTAINER_HEIGHT,
  TINT_CONTAINER_WIDTH,
  eventManager,
} from '../config';

class TintContainer extends ViewContainer {
  public tint: PIXI.Graphics;

  constructor() {
    super();
    this.alpha.toFixed(0.5);
    this.width = TINT_CONTAINER_WIDTH;
    this.height = TINT_CONTAINER_HEIGHT;
    this.tint = new PIXI.Graphics();
    this.tint.beginFill(TINT_BACKGROUND_COLOR);
    this.tint.drawRect(0, 0, TINT_CONTAINER_WIDTH, TINT_CONTAINER_HEIGHT);
    this.tint.alpha = TINT_ALPHA;
    this.addChild(this.tint);
    this.visible = false;
    eventManager.addListener(EventTypes.SHOW_TINT, this.showTint.bind(this));
  }

  private showTint(state: boolean): void {
    this.visible = state;
  }
}

export default TintContainer;
