import { IAnimationStateListener, Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, MAPPED_SYMBOLS_ANIMATIONS, SlotId } from '../../config';
import { isScatter } from '../../utils';
import Animation from '../animations/animation';

import {
  BASE_SLOT_SPINE_ANIMATE_PRIORITY,
  SCATTER_SPINE_ANIMATE_PRIORITY,
  WILD_SPINE_ANIMATE_PRIORITY,
} from './config';

export class SpineAnimateSlot extends PIXI.Container {
  public slotId: SlotId;

  private sprite: PIXI.Sprite;

  private spine: Spine;

  private winAnimationName = '';

  private positionPriority = 0;

  constructor(slotId: SlotId, positionPriority: number) {
    super();

    this.slotId = slotId;
    this.positionPriority = positionPriority;
    this.scale.set(1);

    this.sprite = new PIXI.Sprite(PIXI.Texture.from(MAPPED_SYMBOLS[slotId]));
    this.sprite.anchor.set(0.5, 0.5);
    this.sprite.visible = true;

    this.spine = new Spine(PIXI.Loader.shared.resources[MAPPED_SYMBOLS_ANIMATIONS[slotId].src!]!.spineData!);
    this.spine.visible = false;
    this.winAnimationName = MAPPED_SYMBOLS_ANIMATIONS[this.slotId].animation!;

    this.addChild(this.sprite, this.spine);

    this.spine.state.addListener({
      complete: () => {
        PIXI.Ticker.shared.addOnce(
          () => {
            this.idle = true;
          },
          this,
          PIXI.UPDATE_PRIORITY.HIGH,
        );
      },
    });
  }

  private getPriority(): number {
    if (isScatter(this.slotId)) {
      return SCATTER_SPINE_ANIMATE_PRIORITY + this.positionPriority;
    } else if (this.slotId === SlotId.WL) {
      return WILD_SPINE_ANIMATE_PRIORITY + this.positionPriority;
    }
    return BASE_SLOT_SPINE_ANIMATE_PRIORITY + this.positionPriority;
  }

  public startStopAnimation(): void {
    this.idle = true;
    this.spine.state.addEmptyAnimation(0, 0, 0);
    this.zIndex = 5;
  }

  public startWinAnimation(): void {
    this.idle = false;
    this.spine.state.setAnimation(0, this.winAnimationName, false);
    this.zIndex = this.getPriority();
  }

  public getWinAnimation(): Animation {
    const animation = new Animation({});
    animation.duration = (this.spine.skeleton.data.findAnimation(this.winAnimationName)?.duration ?? 0) * 1000;

    const listener: IAnimationStateListener = {
      complete: () => {
        PIXI.Ticker.shared.addOnce(() => {
          animation.onComplete();
        });
      },
    };

    animation.addOnStart(() => {
      this.zIndex = this.getPriority();
      PIXI.Ticker.shared.addOnce(() => {
        this.startWinAnimation();
        this.spine.state.addListener(listener);
      });
    });

    animation.addOnSkip(() => {
      this.idle = true;
      this.spine.state.removeListener(listener);
    });
    animation.addOnComplete(() => {
      this.spine.state.removeListener(listener);
    });

    return animation;
  }

  public skip(): void {
    this.idle = true;
    this.spine.skeleton.setToSetupPose();
  }

  public set idle(value: boolean) {
    this.sprite.visible = value;
    this.spine.visible = !value;
    this.zIndex = value ? 0 : this.getPriority();
  }

  public get idle(): boolean {
    return this.sprite.visible;
  }

  public set tint(value: number) {
    this.sprite.tint = value;
  }
}
