import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Toast } from '@phoenix7dev/shared-components';

import { EventTypes } from '../../global.d';
import {
  configGql,
  setIsShowSoundToast,
  setIsSoundLoading,
  setIsSoundOn,
  setIsSuspended,
  setProgress,
} from '../../gql';
import { IConfig } from '../../gql/d';
import { eventManager } from '../../slotMachine/config';
import { handleChangeRestriction } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import BuyFeature from '../BuyFeature';
import Clock from '../Clock';
import EventListener from '../EventListener';
import Menu from '../MenuButton';
import SlotMachineLayout from '../SlotMachineLayout';
import Spin from '../SpinButton';
import TitleName from '../TitleName/index';

const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IConfig>(configGql);
  const { isSoundOn, isShowSuspendedToast } = data!;
  const [isShowContent, setShowContent] = useState(false);
  const isLoading = useReactiveVar(setIsSoundLoading);
  const progress = useReactiveVar(setProgress);

  useEffect(() => {
    AudioApi.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    setShowContent(true);
  }, []);

  if (!isShowContent) return null;

  return (
    <>
      <EventListener />
      {progress.wasLoaded && (
        <>
          <TitleName />
          <Clock />
        </>
      )}
      <SlotMachineLayout />
      <BuyFeature />
      <AutoPlaySettingsMenu />
      <Menu />
      <BetSettingsMenu />
      <Spin />
      {progress.wasLoaded && (
        <>
          {AudioApi.isRestricted &&
            !AudioApi.restrictionChangedOnIntroScreen &&
            !AudioApi.hideRestrictionModal &&
            !isShowSuspendedToast &&
            setIsSoundOn() && (
              <Toast
                title={t<string>('soundWarningTitle')}
                text={t<string>('soundWarningText')}
                btnText={t<string>('soundWarningBtn')}
                isLoading={isLoading}
                handleClick={() => {
                  if (!AudioApi.isInitialized) {
                    eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
                  }

                  AudioApi.changeRestriction(
                    false,
                    [],
                    () => setIsSoundLoading(true),
                    () => {
                      setIsShowSoundToast(false);
                      handleChangeRestriction();
                    },
                  );
                }}
              />
            )}
          {isShowSuspendedToast && setIsSoundOn() && (
            <Toast
              title={t<string>('suspendedWarningTitle')}
              text={t<string>('suspendedWarningText')}
              btnText={t<string>('suspendedWarningBtn')}
              handleClick={() => {
                setIsSuspended(false);
                AudioApi.unSuspend();
                handleChangeRestriction();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default GameScreen;
