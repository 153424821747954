import { useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { infoBuyFeatureDesc, infoFeatureFreeSpinDescs } from '../../config/gameRules';
import { setSlotConfig } from '../../gql';
import { ISlotConfig } from '../../gql/d';
import SlotMachine from '../../slotMachine';

import styles from './info.module.scss';

const BuyFeature: React.FC<{ icon: string }> = ({ icon }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className={styles['title']}>{t('infoBuyFeatureTitle')}</h2>
      <div className={`${styles['container']}`}>
        <img className="buyFeatureBtn" draggable="false" src={icon} alt="" />
      </div>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoBuyFeatureDesc.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </div>
  );
};

const FreeSpinFeature: React.VFC = () => {
  const { t } = useTranslation();
  const [infoBuyFeatureIcon, setInfoBuyFeatureIcon] = useState('');
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  useEffect(() => {
    const extractor: PIXI.Extract = SlotMachine.getInstance().getApplication().renderer.plugins[
      'extract'
    ]! as PIXI.Extract;
    setInfoBuyFeatureIcon(extractor.base64(SlotMachine.getInstance().buyFeatureButton));
  }, []);

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoFeatureFreeSpinTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoFeatureFreeSpinDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
      {isBuyFeatureEnabled ? <BuyFeature icon={infoBuyFeatureIcon} /> : <></>}
    </section>
  );
};

const Features: React.VFC = () => {
  return (
    <div className={styles['gameRules']}>
      <FreeSpinFeature />
    </div>
  );
};

export default Features;
