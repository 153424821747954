import { GAME_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH } from '../config';

// BUY FEATURE BTN
export const FEATURE_BTN_CONTAINER_POSITION_X = -20;
export const FEATURE_BTN_CONTAINER_POSITION_Y = 40;
export const FEATURE_BTN_LANDSCAPE_WIDTH = 150;
export const FEATURE_BTN_LANDSCAPE_HEIGHT = 100;

export const FEATURE_BTN_PORTRAIT_WIDTH = 150;
export const FEATURE_BTN_PORTRAIT_HEIGHT = 100;

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_X_OFFSET = FEATURE_BTN_CONTAINER_POSITION_X;
export const FEATURE_BTN_TEXT_Y_OFFSET = FEATURE_BTN_CONTAINER_POSITION_Y;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;

export const FEATURE_BTN_LANDSCAPE_TEXT_SCALE = 0.8;
export const FEATURE_BTN_PORTRAIT_TEXT_SCALE = 0.8;

// BUY FEATURE POPUP
export const FEATURE_POPUP_POSITION_X = SLOTS_CONTAINER_WIDTH / 2;
export const FEATURE_POPUP_POSITION_Y = SLOTS_CONTAINER_HEIGHT / 2;
export const FEATURE_POPUP_SCALE = 1;

export const FEATURE_POPUP_TEXT_ALPHA = 0.25;
export const FEATURE_POPUP_TITLE_POSITION_Y = -310;
export const FEATURE_POPUP_TITLE_POSITION_X = 0;

export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 200;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X - 200;

export const FEATURE_POPUP_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_OK_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X + 200;

export const FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_X = 0;
export const FEATURE_POPUP_TOTAL_COST_TEXT_POSITION_Y = -150;

export const FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_X = 0;
export const FEATURE_POPUP_TOTAL_COST_TEXT_AMOUNT_POSITION_Y = -50;

export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_X = 0;
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y = 40;

export const FEATURE_POPUP_INPUT_WIDTH = 260;
export const FEATURE_POPUP_INPUT_HEIGHT = 50;
export const FEATURE_POPUP_INPUT_POSITION_Y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y + 60;
export const FEATURE_POPUP_INPUT_POSITION_X = 0;

export const FEATURE_POPUP_BET_VALUE_POSITION_Y = FEATURE_POPUP_INPUT_POSITION_Y + 25;
export const FEATURE_POPUP_BET_VALUE_POSITION_X = FEATURE_POPUP_INPUT_POSITION_X;

export const FEATURE_POPUP_MINUS_BTN_WIDTH = 70;
export const FEATURE_POPUP_MINUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = FEATURE_POPUP_INPUT_POSITION_Y - 10;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X =
  FEATURE_POPUP_INPUT_POSITION_X - FEATURE_POPUP_INPUT_WIDTH / 2 - FEATURE_POPUP_MINUS_BTN_WIDTH / 2;

export const FEATURE_POPUP_PLUS_BTN_WIDTH = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = FEATURE_POPUP_INPUT_POSITION_Y - 10;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X =
  FEATURE_POPUP_INPUT_POSITION_X + FEATURE_POPUP_INPUT_WIDTH / 2 + FEATURE_POPUP_PLUS_BTN_WIDTH / 2;

export const FEATURE_POPUP_MIN = 1;
export const FEATURE_POPUP_MAX = 40;

// BUY FEATURE CONFIRM
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = -280;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_X = GAME_CONTAINER_WIDTH / 2;

export const FEATURE_POPUP_CONFIRM_TITLE2_POSITION_Y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y + 50;
export const FEATURE_POPUP_CONFIRM_TITLE2_POSITION_X = GAME_CONTAINER_WIDTH / 2;

export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = FEATURE_POPUP_CONFIRM_TITLE2_POSITION_Y + 100;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;

export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y + 70;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;

export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y = FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y + 200;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X - 200;

export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X = FEATURE_POPUP_TITLE_POSITION_X + 200;
